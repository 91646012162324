import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";

import { useMsal } from "@azure/msal-react";
import { silentRequest } from "../../../../authConfig";

// import * as signalR from "@microsoft/signalr";

import paginate from "jw-paginate";

import { Pagination } from "@fluentui/react-experiments/lib/Pagination";

import CountUp from "react-countup";

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  mergeStyleSets,
  TooltipHost,
  ActionButton,
  Spinner,
  CommandBar,
  Stack,
  DocumentCard,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardLogo,
  DocumentCardType,
  Text,
  DefaultButton,
  ContextualMenu,
  ContextualMenuItemType,
  IconButton,
  CheckboxVisibility,
  FontIcon,
  mergeStyles,
  DatePicker,
  PrimaryButton,
  Pivot,
  PivotItem,
  Link,
  MessageBar,
  MessageBarType,
  DirectionalHint,
  Dropdown
} from "@fluentui/react";

import {
  getFileTypeIconProps,
} from "@fluentui/react-file-type-icons";

// const moment = require('moment-timezone');

// const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

const serviceProvider = {
  StreamItConsulting: "stream-it-consulting",
  GinkgoSoft: "ginkgo-soft",
  Brainergy: "brainergy",
}

const homeStyles = {
  //width: '208',
  //height: '10vh',
  //width: 185,
  //height: 70,
  //boxSizing: 'border-box',
  //border: '1px solid #eee',
  //overflowY: 'auto',
  //paddingTop: '10vh',
  //background: '#FFFFFF',
  //background: '#106ebe',
  border: 0,
  /* paddingTop: '2%',
  paddingLeft: '2%',
  paddingRight: '2%', */
  margin: 0,
  //minHeight: '100vh',
  width: "100%",
  background: "#FAF9F8",
};

//ProgressIndicator
//const centerItem = { display: "flex", alignItems: "center", justifyContent: "center" };
//Progress Indicator Setting
//const intervalDelay = 100;
//const intervalIncrement = 0.01;

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: "16px",
  },
  fileIconCell: {
    textAlign: "center",
    selectors: {
      "&:before": {
        content: ".",
        display: "inline-block",
        verticalAlign: "middle",
        height: "100%",
        width: "0px",
        visibility: "hidden",
      },
    },
  },
  fileIconImg: {
    verticalAlign: "middle",
    maxHeight: "16px",
    maxWidth: "16px",
  },
  controlWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  exampleToggle: {
    display: "inline-block",
    marginBottom: "10px",
    marginRight: "30px",
  },
  selectionDetails: {
    marginBottom: "20px",
  },
});
/* 
const shimmeredDetailsListProps = {
renderedWindowsAhead: 0,
renderedWindowsBehind: 0,
};
*/

const cardStyles = {
  cardStyles: {
    root: {
      background: "white",
      //padding: 20,
      //marginRight: '50%',
      //marginRight: '40px',
      //borderTop: '5px solid #0078d4',
      borderLeft: "5px solid #0078d4",
      width: "20%",
      minWidth: "250px",
      // maxWidth: "424px",
      maxWidth: "325px",
      height: "130px",
      //margin: 'auto',
      //padding: '0 10% 0 10% ',
      //display: 'flex',
      //alignItems: 'center',
      //justifyContent: 'center',
    },
  },
  header: {
    root: {
      fontSize: 20,
      fontWeight: "bold",
      //paddingTop: '5%',
      paddingTop: "20px",
      textAlign: "center",
      //color: '#696969'
    },
  },
  amount: {
    root: {
      fontSize: 30,
      //paddingBottom: 20,
      //paddingBottom: '8%',
      paddingBottom: "25px",
      //paddingTop: '20%',
      //paddingRight: '20%',
      textAlign: "center",
    },
  },
};

function formatDateTime(date) {
  //let d = new Date(date).toLocaleString("en-GB", {timeZone: "Asia/Bangkok"}),
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = "" + d.getHours(),
    minute = "" + d.getMinutes(),
    second = "" + d.getSeconds();

  //console.log(d.toString());

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour.length < 2) hour = "0" + hour;
  if (minute.length < 2) minute = "0" + minute;
  if (second.length < 2) second = "0" + second;

  return [year, month, day, hour, minute, second].join("");
}

const onFormatDate = (date, format) => {
  if (format === "CE") {
    return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + (date.getFullYear() + 543);
  }
};

const formatDate = (d) => {
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const AllDocuments = (props) => {
  console.log("Home render!!!");

  const history = useHistory();

  const { instance } = useMsal();
  //const account = useAccount(accounts[0] || {});
  const account = instance.getAllAccounts()[0];

  //let token = b2cauth.getAccessToken();
  //console.log('Bearer', token);

  //const token = props.token;
  //const token = props.token;
  //

  //const api_key = props.api_key;
  //console.log('Api-Key:', props.api_key);

  //const items = [];
  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  // const [totalProcessingDocuments, setTotalProcessingDocuments] = useState(0);
  //const [clickDelete, setClickDelete] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem("uploadXml-pageSize") * 1 || 100);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem("uploadXml-currentPage") * 1 || 1);

  const [serviceProviderId, setServiceProviderId] = useState("ginkgo-soft");

  /* 
  const [pageData, setPageData] = useState({
    totalItems: '',
    currentPage: '',
    pageSize: '',
    totalPages: '',
    startPage: '',
    endPage: '',
    startIndex: '',
    endIndex: '',
    pages: [] 
  });
*/
  //const [percentComplete, setPercentComplete] = useState(0);

  //CommandBars Items
  const [documentType, setDocumentType] = useState(
    (sessionStorage.getItem("uploadXml-documentType-key") && {
      key: sessionStorage.getItem("uploadXml-documentType-key"),
      text: sessionStorage.getItem("uploadXml-documentType-text"),
    }) || {
      key: "ALL",
      text: "เอกสารทุกประเภท",
    }
  );
  /* 
      const [deletedStatus, setDeletedStatus] = useState(
          (sessionStorage.getItem("uploadXml-deletedStatus-key") && {
              key: sessionStorage.getItem("uploadXml-deletedStatus-key"),
              text: sessionStorage.getItem("uploadXml-deletedStatus-text"),
          }) || { key: "allDeletedStatus", text: "เอกสารทั้งหมด" }
      );
   */
  const [deletedStatus, setDeletedStatus] = useState(
    (sessionStorage.getItem("uploadXml-deletedStatus-key") && {
      key: sessionStorage.getItem("uploadXml-deletedStatus-key"),
      text: sessionStorage.getItem("uploadXml-deletedStatus-text"),
    }) || { key: "false", text: "เอกสารที่ไม่ถูกลบ" }
  );

  const [rdStatusType, setRdStatusType] = useState(
    (sessionStorage.getItem("uploadXml-rdStatusType-key") && {
      key: sessionStorage.getItem("uploadXml-rdStatusType-key"),
      text: sessionStorage.getItem("uploadXml-rdStatusType-text"),
    }) || { key: "allRdStatus", text: "สถานะนำส่งทั้งหมด" }
  );

  /* 
      const [createdByFilter, setCreatedByFilter] = useState(
          (sessionStorage.getItem("uploadXml-createdByFilter-key") && {
              key: sessionStorage.getItem("uploadXml-createdByFilter-key"),
              text: sessionStorage.getItem("uploadXml-createdByFilter-text"),
          }) || {
              key: "allCreatedBy",
              text: "บริษัททั้งหมด",
          }
      );
  
      const [createdByFilterItems, setCreatedByFilterItems] = useState([
          {
              key: "allCreatedBy",
              text: "บริษัททั้งหมด",
              iconProps: { iconName: "CityNext2" },
              onClick: () => {
                  console.log("บริษัททั้งหมด");
                  setCreatedByFilter({
                      key: "allCreatedBy",
                      text: "บริษัททั้งหมด",
                  });
                  sessionStorage.setItem("uploadXml-createdByFilter-key", "allCreatedBy");
                  sessionStorage.setItem("uploadXml-createdByFilter-text", "บริษัททั้งหมด");
  
                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);
  
                  setTotalDocuments(0);
                  setTotalVat(0);
                  setTotalGrand(0);
              },
              //['data-automation-id']: 'newEmailButton', // optional
          },
      ]);
   */
  const [companiesFilter, setCompaniesFilter] = useState(
    (sessionStorage.getItem("uploadXml-companiesFilter-key") && {
      key: sessionStorage.getItem("uploadXml-companiesFilter-key"),
      text: sessionStorage.getItem("uploadXml-companiesFilter-text"),
    }) || {
      key: "allCompanies",
      text: "บริษัททั้งหมด",
    }
  );

  const [companiesFilterItems, setCompaniesFilterItems] = useState([
    {
      key: "allCompanies",
      text: "บริษัททั้งหมด",
      iconProps: { iconName: "CityNext2" },
      onClick: () => {
        console.log("บริษัททั้งหมด");
        setCompaniesFilter({
          key: "allCompanies",
          text: "บริษัททั้งหมด",
        });
        sessionStorage.setItem("uploadXml-companiesFilter-key", "allCreatedBy");
        sessionStorage.setItem("uploadXml-companiesFilter-text", "บริษัททั้งหมด");

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        uploadingXmlStop.current = false;

        // setEtaxUserLastLogin("");
        // setUploadingXmlStop(false);
        setUploadingDocumentId("");
        setUploadingDocumentNumber("");
        setUploadingDocumentStatus("");
        setUploadingDocumentMessage("");
        setUploadingDocumentRefNo("");
        setUploadingDocumentFileName("");
      },
      //['data-automation-id']: 'newEmailButton', // optional
    },
  ]);

  //Display Summary
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalVat, setTotalVat] = useState(0);
  const [totalGrand, setTotalGrand] = useState(0);

  const [totalPending, setTotalPending] = useState(0);
  const [totalSpPending, setTotalSpPending] = useState(0);
  const [totalFail, setTotalFail] = useState(0);
  const [totalSuccessful, setTotalSuccessful] = useState(0);
  const [totalNotYetDelivered, setTotalNotYetDeliveries] = useState(0);

  const [showRepeatNumber, setShowRepeatNumber] = useState(false);
  const [duplicatedNumbers, setDuplicatedNumbers] = useState([])
  // const [uploadAble, setUploadAble] = useState(true)

  const [search, setSearch] = useState("");
  // const [previousSearch, setPreviousSearch] = useState("");

  const uploadingXmlStop = useRef(false);

  // const [etaxUserLastLogin, setEtaxUserLastLogin] = useState("");
  // const [uploadingXmlStop, setUploadingXmlStop] = useState(false);
  const [uploadingDocumentId, setUploadingDocumentId] = useState("");
  const [uploadingDocumentNumber, setUploadingDocumentNumber] = useState("");
  const [uploadingDocumentStatus, setUploadingDocumentStatus] = useState("");
  const [uploadingDocumentMessage, setUploadingDocumentMessage] = useState("");
  const [uploadingDocumentFileName, setUploadingDocumentFileName] = useState("");
  const [uploadingDocumentRefNo, setUploadingDocumentRefNo] = useState("");

  const [primarySort, setPrimarySort] = useState(
    // sessionStorage.getItem("uploadXml-primarySort") || "createdTime"
    // sessionStorage.getItem("uploadXml-primarySort") || "documentDate"
    sessionStorage.getItem("uploadXml-primarySort") || "number"
  );

  const [sortDirection, setSortDirection] = useState(
    sessionStorage.getItem("uploadXml-sortDirection") || "DESC"
  );
  // const [sortBy, setSortBy] = useState("createdTime");

  const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-createdTime") && sessionStorage.getItem("uploadXml-sort-createdTime") === "true") {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-number") && sessionStorage.getItem("uploadXml-sort-number") === "false") {
      return false;
    } else {
      return true;
    }
  });
  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-documentDate") && sessionStorage.getItem("uploadXml-sort-documentDate") === "true") {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-customerName") && sessionStorage.getItem("uploadXml-sort-customerName") === "true") {
      return true;
    } else {
      return false;
    }
  });
/* 
  const [isSortedDescendingCustomerPhone, setIsSortedDescendingCustomerPhone] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-customerPhone") && sessionStorage.getItem("uploadXml-sort-customerPhone") === "true") {
      return true;
    } else {
      return false;
    }
  });

  const [isSortedDescendingVatTotal, setIsSortedDescendingVatTotal] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-vatTotal") && sessionStorage.getItem("uploadXml-sort-vatTotal") === "true") {
      return true;
    } else {
      return false;
    }
  });
   */
  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-grandTotal") && sessionStorage.getItem("uploadXml-sort-grandTotal") === "true") {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingName, setIsSortedDescendingName] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-name") && sessionStorage.getItem("uploadXml-sort-name") === "true") {
      return true;
    } else {
      return false;
    }
  });
/*   
  const [isSortedDescendingEmailReceiver, setIsSortedDescendingEmailReceiver] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-emailReceiver") && sessionStorage.getItem("uploadXml-sort-emailReceiver") === "true") {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingEmailTime, setIsSortedDescendingEmailTime] = useState(() => {
    if (sessionStorage.getItem("uploadXml-sort-emailTime") && sessionStorage.getItem("uploadXml-sort-emailTime") === "true") {
      return true;
    } else {
      return false;
    }
  });

  const getMonth = (goBack) => {
    let monthNames = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];

    let d = new Date();
    //console.log('new Date(): ', d);

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    //d.setMonth(d.getMonth() - goBack);
    //console.log(d.toLocaleDateString());
    //console.log(d.getMonth());

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + " " + year,
    };
    //return monthNames[d.getMonth()] + ' ' + year;
    //let m = d.getMonth();

    //console.log(_month);

    //return _month;
  };
 */
  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem("uploadXml-queryMonth-key") && {
      type: "dateRange",
      key: sessionStorage.getItem("uploadXml-queryMonth-key"),
      text: sessionStorage.getItem("uploadXml-queryMonth-text"),
    }) || {
      type: "dateRange",
      key: formatDate(new Date()) + ":" + formatDate(new Date()),
      text: formatDate(new Date()) + " - " + formatDate(new Date()),
    }
  );

  // const [timeRangeDate, setTimeRangeDate] = useState(false);
  const [fromDate, setFromDate] = useState(sessionStorage.getItem("uploadXml-fromDate") ? new Date(sessionStorage.getItem("uploadXml-fromDate")) : new Date());
  const [toDate, setToDate] = useState(sessionStorage.getItem("uploadXml-toDate") ? new Date(sessionStorage.getItem("uploadXml-toDate")) : new Date());
  const [timeRangeError, setTimeRangeError] = useState(false);

  const getFrom = (day, month, year) => {
    let _firstDay = new Date(year, month, day);
    console.log("First day: ", formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);
    console.log("Last day: ", formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const getFrom2 = (day, month, year) => {
    let _firstDay = new Date(year, month, day);

    return Math.floor(_firstDay / 1000);
  };

  const getTo2 = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);

    return Math.floor(_lastDay / 1000);
  };

  const [from, setFrom] = useState(sessionStorage.getItem("uploadXml-from") || getFrom(new Date().getDate(), new Date().getMonth(), new Date().getFullYear()));
  const [to, setTo] = useState(sessionStorage.getItem("uploadXml-to") || getTo(new Date().getDate(), new Date().getMonth(), new Date().getFullYear()));

  // const [from2, setFrom2] = useState(sessionStorage.getItem("uploadXml-from2") || getFrom2(new Date().getDate(), new Date().getMonth(), new Date().getFullYear()));
  // const [to2, setTo2] = useState(sessionStorage.getItem("uploadXml-to2") || getTo2(new Date().getDate(), new Date().getMonth(), new Date().getFullYear()));
  /* 
      const formatDate = (d) => {
          //let d = new Date(date),
          let month = "" + (d.getMonth() + 1);
          let day = "" + d.getDate();
          let year = d.getFullYear();
  
          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;
  
          return [year, month, day].join("-");
      };
     
   */
  // const [date, setDate] = useState(sessionStorage.getItem('date') ? new Date(sessionStorage.getItem('date')) : new Date());
/* 
  function convertDateToUTC() {
    var date = new Date();
    var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

    return new Date(now_utc).toISOString();
  }
 */
  const [isProcessDocument, setIsProcessDocument] = useState(false);
  const [isProcessDocumentComplete, setIsProcessDocumentComplete] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        console.log("isCreateDocument: ", isProcessDocument);
        console.log("isCreateDocumentComplete: ", isProcessDocumentComplete);
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [pageDropdown, setPageDropdown] = useState([]);
  const [dateType, setDateType] = useState(
    /* 
            (sessionStorage.getItem("uploadXml-dateType-key") && {
                key: sessionStorage.getItem("uploadXml-dateType-key"),
                text: sessionStorage.getItem("uploadXml-dateType-text"),
            }) || { key: "created", text: "วันที่สร้างเอกสาร" }
     */
    (sessionStorage.getItem("uploadXml-dateType-key") && {
      key: sessionStorage.getItem("uploadXml-dateType-key"),
      text: sessionStorage.getItem("uploadXml-dateType-text"),
    }) || { key: "document", text: "วันที่บนเอกสาร" }
  );

  // const [role, setRole] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  const resetSort = () => {
    if (dateType.key === "document") {
      setPrimarySort("documentDate");
      setIsSortedDescendingDocumentDate(true);
      setIsSortedDescendingCreatedTime(false);
      setIsSortedDescendingNumber(false);

      sessionStorage.setItem("uploadXml-primarySort", "documentDate");
      sessionStorage.setItem("uploadXml-sort-documentDate", "true");
      sessionStorage.setItem("uploadXml-sort-createdTime", "false");
      sessionStorage.setItem("uploadXml-sort-number", "false");
    } else if (dateType.key === "created") {
      setPrimarySort("createdTime");
      setIsSortedDescendingCreatedTime(true);
      setIsSortedDescendingDocumentDate(false);
      setIsSortedDescendingNumber(false);

      sessionStorage.setItem("uploadXml-primarySort", "createdTime");
      sessionStorage.setItem("uploadXml-sort-createdTime", "true");
      sessionStorage.setItem("uploadXml-sort-documentDate", "false");
      sessionStorage.setItem("uploadXml-sort-number", "false");
    } else {
      setPrimarySort("number");
      setIsSortedDescendingCreatedTime(false);
      setIsSortedDescendingDocumentDate(false);
      setIsSortedDescendingNumber(true);

      sessionStorage.setItem("uploadXml-primarySort", "number");
      sessionStorage.setItem("uploadXml-sort-createdTime", "false");
      sessionStorage.setItem("uploadXml-sort-documentDate", "false");
      sessionStorage.setItem("uploadXml-sort-number", "true");
    }

    // setIsSortedDescendingNumber(false);
    setIsSortedDescendingCustomerName(false);
    // setIsSortedDescendingCustomerPhone(false);
    // setIsSortedDescendingVatTotal(false);
    setIsSortedDescendingGrandTotal(false);
    setIsSortedDescendingName(false);

    setSortDirection("DESC");

    // sessionStorage.setItem("uploadXml-sort-number", "false");
    sessionStorage.setItem("uploadXml-sort-customerName", "false");
    sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
    sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
    sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
    sessionStorage.setItem("uploadXml-sort-name", "false");

    sessionStorage.setItem("uploadXml-sortDirection", "DESC");


  };

  const DayPickerStrings = {
    months: ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"],

    shortMonths: ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],

    days: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"],

    shortDays: ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],

    goToToday: "ไปวันที่ปัจจุบัน",
    prevMonthAriaLabel: "ไปเดือนที่แล้ว",
    nextMonthAriaLabel: "ไปเดือนถัดไป",
    prevYearAriaLabel: "ไปปีที่แล้ว",
    nextYearAriaLabel: "ไปปีถัดไป",
    closeButtonAriaLabel: "ปิด",
    monthPickerHeaderAriaLabel: "{0}, เลือกเพื่อเปลี่ยนปี",
    yearPickerHeaderAriaLabel: "{0}, เลือกเพื่อเปลี่ยนเดือน",

    isRequiredErrorMessage: "กรุณาเลือกวันที่",

    invalidInputErrorMessage: "รูปแบบวันที่ไม่ถูกต้อง",
  };

  function getDateText(monthObject) {
    if (monthObject.type === "dateRange") {
      let _splitDate = monthObject.key.split(":");
      let _fromSplit = _splitDate[0].split("-");
      let _toSplit = _splitDate[1].split("-");

      let _from = _fromSplit[2] + "/" + _fromSplit[1] + "/" + (Number(_fromSplit[0]) + 543);
      let _to = _toSplit[2] + "/" + _toSplit[1] + "/" + (Number(_toSplit[0]) + 543);

      return _from + " - " + _to;
    }

    if (monthObject.text === "เดือนนี้" || monthObject.text === "This Month") return "เดือนนี้";

    let monthNames = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    // if (i18n.language === 'th') {
    _year = _year + 543;
    // }

    return monthNames[_monthIndex] + " " + _year;
  }

  let commandBarItems = [
    {
      key: documentType.key,
      text: documentType.text,
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "Page" },
      disabled: !loadDataComplete || isProcessDocument,
      subMenuProps: {
        items: [
          {
            key: "ALL",
            text: "เอกสารทุกประเภท",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("เอกสารทุกประเภท");
              setDocumentType({
                key: "ALL",
                text: "เอกสารทุกประเภท",
              });
              sessionStorage.setItem("uploadXml-documentType-key", "ALL");
              sessionStorage.setItem("uploadXml-documentType-text", "เอกสารทุกประเภท");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "RECEIPT-TAXINVOICE",
            text: "ใบเสร็จรับเงิน/ใบกำกับภาษี",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("ใบเสร็จรับเงิน/ใบกำกับภาษี");
              setDocumentType({
                key: "RECEIPT-TAXINVOICE",
                text: "ใบเสร็จรับเงิน/ใบกำกับภาษี",
              });
              sessionStorage.setItem("uploadXml-documentType-key", "RECEIPT-TAXINVOICE");
              sessionStorage.setItem("uploadXml-documentType-text", "ใบเสร็จรับเงิน/ใบกำกับภาษี");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "RECEIPT-TAXINVOICE-ABB",
            text: "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ");
              setDocumentType({
                key: "RECEIPT-TAXINVOICE-ABB",
                text: "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
              });
              sessionStorage.setItem("uploadXml-documentType-key", "RECEIPT-TAXINVOICE-ABB");
              sessionStorage.setItem("uploadXml-documentType-text", "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "RECEIPT",
            text: "ใบเสร็จรับเงิน",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("ใบเสร็จรับเงิน");
              setDocumentType({
                key: "RECEIPT",
                text: "ใบเสร็จรับเงิน",
              });
              sessionStorage.setItem("uploadXml-documentType-key", "RECEIPT");
              sessionStorage.setItem("uploadXml-documentType-text", "ใบเสร็จรับเงิน");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "INVOICE-TAXINVOICE",
            text: "ใบแจ้งหนี้/ใบกำกับภาษี",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("ใบแจ้งหนี้/ใบกำกับภาษี");
              setDocumentType({
                key: "INVOICE-TAXINVOICE",
                text: "ใบแจ้งหนี้/ใบกำกับภาษี",
              });
              sessionStorage.setItem("uploadXml-documentType-key", "INVOICE-TAXINVOICE");
              sessionStorage.setItem("uploadXml-documentType-text", "ใบแจ้งหนี้/ใบกำกับภาษี");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "DELIVERYORDER-TAXINVOICE",
            text: "ใบส่งของ/ใบกำกับภาษี",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("ใบส่งของ/ใบกำกับภาษี");
              setDocumentType({
                key: "DELIVERYORDER-TAXINVOICE",
                text: "ใบส่งของ/ใบกำกับภาษี",
              });
              sessionStorage.setItem("uploadXml-documentType-key", "DELIVERYORDER-TAXINVOICE");
              sessionStorage.setItem("uploadXml-documentType-text", "ใบส่งของ/ใบกำกับภาษี");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "TAXINVOICE",
            text: "ใบกำกับภาษี",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("ใบกำกับภาษี");
              setDocumentType({
                key: "TAXINVOICE",
                text: "ใบกำกับภาษี",
              });
              sessionStorage.setItem("uploadXml-documentType-key", "TAXINVOICE");
              sessionStorage.setItem("uploadXml-documentType-text", "ใบกำกับภาษี");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "DEBIT-NOTE",
            text: "ใบเพิ่มหนี้",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("ใบเพิ่มหนี้");
              setDocumentType({
                key: "DEBIT-NOTE",
                text: "ใบเพิ่มหนี้",
              });
              sessionStorage.setItem("uploadXml-documentType-key", "DEBIT-NOTE");
              sessionStorage.setItem("uploadXml-documentType-text", "ใบเพิ่มหนี้");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: "CREDIT-NOTE",
            text: "ใบลดหนี้",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("ใบลดหนี้");
              setDocumentType({
                key: "CREDIT-NOTE",
                text: "ใบลดหนี้",
              });
              sessionStorage.setItem("uploadXml-documentType-key", "CREDIT-NOTE");
              sessionStorage.setItem("uploadXml-documentType-text", "ใบลดหนี้");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: "QUOTATION",
            text: "ใบเสนอราคา",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("ใบเสนอราคา");
              setDocumentType({
                key: "QUOTATION",
                text: "ใบเสนอราคา",
              });
              sessionStorage.setItem("uploadXml-documentType-key", "QUOTATION");
              sessionStorage.setItem("uploadXml-documentType-text", "ใบเสนอราคา");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: "INVOICE",
            text: "ใบแจ้งหนี้",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("ใบแจ้งหนี้");
              setDocumentType({
                key: "INVOICE",
                text: "ใบแจ้งหนี้",
              });
              sessionStorage.setItem("uploadXml-documentType-key", "INVOICE");
              sessionStorage.setItem("uploadXml-documentType-text", "ใบแจ้งหนี้");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: "BILLING-NOTE",
            text: "ใบวางบิล",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("ใบวางบิล");
              setDocumentType({
                key: "BILLING-NOTE",
                text: "ใบวางบิล",
              });
              sessionStorage.setItem("uploadXml-documentType-key", "BILLING-NOTE");
              sessionStorage.setItem("uploadXml-documentType-text", "ใบวางบิล");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: "WITHHOLDING-TAX",
            text: "หนังสือรับรองการหักภาษี ณ ที่จ่าย",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("หนังสือรับรองการหักภาษี ณ ที่จ่าย");
              setDocumentType({
                key: "WITHHOLDING-TAX",
                text: "หนังสือรับรองการหักภาษี ณ ที่จ่าย",
              });
              sessionStorage.setItem("uploadXml-documentType-key", "WITHHOLDING-TAX");
              sessionStorage.setItem("uploadXml-documentType-text", "หนังสือรับรองการหักภาษี ณ ที่จ่าย");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
        ],
      },
    },

    {
      key: deletedStatus.key,
      text: deletedStatus.text,
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "Page" },
      // disabled: !loadDataComplete || isProcessDocument,
      disabled: true,
      subMenuProps: {
        items: [
          {
            key: "allDeletedStatus",
            text: "เอกสารทั้งหมด",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("เอกสารทั้งหมด");
              setDeletedStatus({
                key: "allDeletedStatus",
                text: "เอกสารทั้งหมด",
              });
              sessionStorage.setItem("uploadXml-deletedStatus-key", "allDeletedStatus");
              sessionStorage.setItem("uploadXml-deletedStatus-text", "เอกสารทั้งหมด");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "false",
            text: "เอกสารที่ไม่ถูกลบ",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("เอกสารที่ไม่ถูกลบ");
              setDeletedStatus({
                key: "false",
                text: "เอกสารที่ไม่ถูกลบ",
              });
              sessionStorage.setItem("uploadXml-deletedStatus-key", "false");
              sessionStorage.setItem("uploadXml-deletedStatus-text", "เอกสารที่ไม่ถูกลบ");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "true",
            text: "เอกสารที่ถูกลบ",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("เอกสารที่ถูกลบ");
              setDeletedStatus({
                key: "true",
                text: "เอกสารที่ถูกลบ",
              });
              sessionStorage.setItem("uploadXml-deletedStatus-key", "true");
              sessionStorage.setItem("uploadXml-deletedStatus-text", "เอกสารที่ถูกลบ");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
        ],
      },
    },
    {
      key: rdStatusType.key,
      text: rdStatusType.text,
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "Page" },
      disabled: !loadDataComplete || isProcessDocument,
      subMenuProps: {
        items: [
          {
            key: "allRdStatus",
            text: "สถานะนำส่งทั้งหมด",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("สถานะนำส่งทั้งหมด");
              setRdStatusType({
                key: "allRdStatus",
                text: "สถานะนำส่งทั้งหมด",
              });
              sessionStorage.setItem("uploadXml-rdStatusType-key", "allRdStatus");
              sessionStorage.setItem("uploadXml-rdStatusType-text", "สถานะนำส่งทั้งหมด");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "not-yet-delivered",
            text: "ยังไม่ได้นำส่ง",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("ยังไม่ได้นำส่ง");
              setRdStatusType({
                key: "not-yet-delivered",
                text: "ยังไม่ได้นำส่ง",
              });
              sessionStorage.setItem("uploadXml-rdStatusType-key", "not-yet-delivered");
              sessionStorage.setItem("uploadXml-rdStatusType-text", "ยังไม่ได้นำส่ง");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "pending",
            text: "รอนำส่ง",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("รอนำส่ง");
              setRdStatusType({
                key: "pending",
                text: "รอนำส่ง",
              });
              sessionStorage.setItem("uploadXml-rdStatusType-key", "pending");
              sessionStorage.setItem("uploadXml-rdStatusType-text", "รอนำส่ง");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "sp-pending",
            text: "นำส่งแล้ว (รอสถานะ)",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("รอส่งอัตโนมัติ");
              setRdStatusType({
                key: "sp-pending",
                text: "นำส่งแล้ว (รอสถานะ)",
              });
              sessionStorage.setItem("uploadXml-rdStatusType-key", "sp-pending");
              sessionStorage.setItem("uploadXml-rdStatusType-text", "นำส่งแล้ว (รอสถานะ)");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "successful",
            text: "นำส่งสำเร็จ",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("นำส่งสำเร็จ");
              setRdStatusType({
                key: "successful",
                text: "นำส่งสำเร็จ",
              });
              sessionStorage.setItem("uploadXml-rdStatusType-key", "successful");
              sessionStorage.setItem("uploadXml-rdStatusType-text", "นำส่งสำเร็จ");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "fail",
            text: "นำส่งไม่สำเร็จ",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("นำส่งไม่สำเร็จ");
              setRdStatusType({
                key: "fail",
                text: "นำส่งไม่สำเร็จ",
              });
              sessionStorage.setItem("uploadXml-rdStatusType-key", "fail");
              sessionStorage.setItem("uploadXml-rdStatusType-text", "นำส่งไม่สำเร็จ");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: "null",
            text: "ไม่นำส่ง",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("ไม่นำส่ง");
              setRdStatusType({ key: "null", text: "ไม่นำส่ง" });
              sessionStorage.setItem("uploadXml-rdStatusType-key", "null");
              sessionStorage.setItem("uploadXml-rdStatusType-text", "ไม่นำส่ง");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              resetSort();
            },
          },
        ],
      },
    },
    {
      key: companiesFilter.key,
      text: companiesFilter.text,
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "CityNext2" },
      disabled: !loadDataComplete || isProcessDocument || companiesFilterItems.length <= 1,
      subMenuProps: {
        items: companiesFilterItems,
      },
    },
    {
      key: dateType.key,
      text: dateType.text,
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "CalendarSettings" },
      disabled: !loadDataComplete || isProcessDocument,
      subMenuProps: {
        items: [
          {
            key: "created",
            text: "วันที่สร้างเอกสาร",
            iconProps: { iconName: "CalendarSettings" },
            onClick: () => {
              console.log("วันที่สร้างเอกสาร");
              setDateType({
                key: "created",
                text: "วันที่สร้างเอกสาร",
              });
              sessionStorage.setItem("uploadXml-dateType-key", "created");
              sessionStorage.setItem("uploadXml-dateType-text", "วันที่สร้างเอกสาร");

              sessionStorage.setItem("uploadXml-sortDirection", "DESC");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              // setPreviousSearch('');
              // setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              setPrimarySort("createdTime");
              setIsSortedDescendingCreatedTime(true);

              setIsSortedDescendingNumber(false);
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingCustomerName(false);
              // setIsSortedDescendingCustomerPhone(false);
              // setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);

              setSortDirection("DESC");
            },
          },
          {
            key: "document",
            text: "วันที่บนเอกสาร",
            iconProps: { iconName: "CalendarSettings" },
            onClick: () => {
              console.log("วันที่บนเอกสาร");
              setDateType({
                key: "document",
                text: "วันที่บนเอกสาร",
              });
              sessionStorage.setItem("uploadXml-dateType-key", "document");
              sessionStorage.setItem("uploadXml-dateType-text", "วันที่บนเอกสาร");

              sessionStorage.setItem("uploadXml-sortDirection", "DESC");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              // setPreviousSearch('');
              // setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              setPrimarySort("documentDate");
              setIsSortedDescendingDocumentDate(true);

              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              // setIsSortedDescendingCustomerPhone(false);
              // setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);

              setSortDirection("DESC");

            },
          },
        ],
      },
    },
    /* 
    {
        key: queryMonth.key,
        text: queryMonth.text,
        iconProps: { iconName: "Calendar" },
        disabled: !loadDataComplete || isProcessDocument,
        //href: 'https://developer.microsoft.com/en-us/fluentui',
        subMenuProps: {
            items: [
                {
                    key: getMonth(0).year + "" + getMonth(0).key,
                    text: getMonth(0).text,
                    iconProps: { iconName: "Calendar" },
                    onClick: () => {
                        console.log("Selected month: ", getMonth(0).key);
                        setQueryMonth({
                            key: getMonth(0).year + "" + getMonth(0).key,
                            text: getMonth(0).text,
                        });
                        sessionStorage.setItem(
                            "queryMonth-key",
                            getMonth(0).year + "" + getMonth(0).key
                        );
                        sessionStorage.setItem(
                            "queryMonth-text",
                            getMonth(0).text
                        );
                        //setFirstLastDay(getMonth(0).month, getMonth(0).year);
                        setFrom(
                            getFrom(getMonth(0).month, getMonth(0).year)
                        );
                        setTo(getTo(getMonth(0).month, getMonth(0).year));
                        sessionStorage.setItem(
                            "from",
                            getFrom(getMonth(0).month, getMonth(0).year)
                        );
                        sessionStorage.setItem(
                            "to",
                            getTo(getMonth(0).month, getMonth(0).year)
                        );

                        setLoadDataComplete(false);
                        setItems([]);
                        setGetNewDocuments(true);

                        setTotalDocuments(0);
                        setTotalVat(0);
                        setTotalGrand(0);

                        setCurrentPage(1);

                        resetSort();
                    },
                    //['data-automation-id']: 'newEmailButton', // optional
                },
                {
                    key: getMonth(1).year + "" + getMonth(1).key,
                    text: getMonth(1).text,
                    iconProps: { iconName: "Calendar" },
                    onClick: () => {
                        console.log("Selected month: ", getMonth(1).key);
                        setQueryMonth({
                            key: getMonth(1).year + "" + getMonth(1).key,
                            text: getMonth(1).text,
                        });
                        sessionStorage.setItem(
                            "queryMonth-key",
                            getMonth(1).year + "" + getMonth(1).key
                        );
                        sessionStorage.setItem(
                            "queryMonth-text",
                            getMonth(1).text
                        );
                        //setFirstLastDay(getMonth(1).month, getMonth(1).year);
                        setFrom(
                            getFrom(getMonth(1).month, getMonth(1).year)
                        );
                        setTo(getTo(getMonth(1).month, getMonth(1).year));
                        sessionStorage.setItem(
                            "from",
                            getFrom(getMonth(1).month, getMonth(1).year)
                        );
                        sessionStorage.setItem(
                            "to",
                            getTo(getMonth(1).month, getMonth(1).year)
                        );

                        setLoadDataComplete(false);
                        setItems([]);
                        setGetNewDocuments(true);

                        setTotalDocuments(0);
                        setTotalVat(0);
                        setTotalGrand(0);

                        setCurrentPage(1);

                        resetSort();
                    },
                    //['data-automation-id']: 'newEmailButton', // optional
                },
                {
                    key: getMonth(2).year + "" + getMonth(2).key,
                    text: getMonth(2).text,
                    iconProps: { iconName: "Calendar" },
                    onClick: () => {
                        console.log("Selected month: ", getMonth(2).key);
                        setQueryMonth({
                            key: getMonth(2).year + "" + getMonth(2).key,
                            text: getMonth(2).text,
                        });
                        sessionStorage.setItem(
                            "queryMonth-key",
                            getMonth(2).year + "" + getMonth(2).key
                        );
                        sessionStorage.setItem(
                            "queryMonth-text",
                            getMonth(2).text
                        );
                        //setFirstLastDay(getMonth(2).month, getMonth(2).year);
                        setFrom(
                            getFrom(getMonth(2).month, getMonth(2).year)
                        );
                        setTo(getTo(getMonth(2).month, getMonth(2).year));
                        sessionStorage.setItem(
                            "from",
                            getFrom(getMonth(2).month, getMonth(2).year)
                        );
                        sessionStorage.setItem(
                            "to",
                            getTo(getMonth(2).month, getMonth(2).year)
                        );

                        setLoadDataComplete(false);
                        setItems([]);
                        setGetNewDocuments(true);

                        setTotalDocuments(0);
                        setTotalVat(0);
                        setTotalGrand(0);

                        setCurrentPage(1);

                        resetSort();
                    },
                    //['data-automation-id']: 'newEmailButton', // optional
                },
                {
                    key: getMonth(3).year + "" + getMonth(3).key,
                    text: getMonth(3).text,
                    iconProps: { iconName: "Calendar" },
                    onClick: () => {
                        console.log("Selected month: ", getMonth(3).key);
                        setQueryMonth({
                            key: getMonth(3).year + "" + getMonth(3).key,
                            text: getMonth(3).text,
                        });
                        sessionStorage.setItem(
                            "queryMonth-key",
                            getMonth(3).year + "" + getMonth(3).key
                        );
                        sessionStorage.setItem(
                            "queryMonth-text",
                            getMonth(3).text
                        );
                        //setFirstLastDay(getMonth(3).month, getMonth(3).year);
                        setFrom(
                            getFrom(getMonth(3).month, getMonth(3).year)
                        );
                        setTo(getTo(getMonth(3).month, getMonth(3).year));
                        sessionStorage.setItem(
                            "from",
                            getFrom(getMonth(3).month, getMonth(3).year)
                        );
                        sessionStorage.setItem(
                            "to",
                            getTo(getMonth(3).month, getMonth(3).year)
                        );

                        setLoadDataComplete(false);
                        setItems([]);
                        setGetNewDocuments(true);

                        setTotalDocuments(0);
                        setTotalVat(0);
                        setTotalGrand(0);

                        setCurrentPage(1);

                        resetSort();
                    },
                    //['data-automation-id']: 'newEmailButton', // optional
                },
                {
                    key: getMonth(4).year + "" + getMonth(4).key,
                    text: getMonth(4).text,
                    iconProps: { iconName: "Calendar" },
                    onClick: () => {
                        console.log("Selected month: ", getMonth(4).key);
                        setQueryMonth({
                            key: getMonth(4).year + "" + getMonth(4).key,
                            text: getMonth(4).text,
                        });
                        sessionStorage.setItem(
                            "queryMonth-key",
                            getMonth(4).year + "" + getMonth(4).key
                        );
                        sessionStorage.setItem(
                            "queryMonth-text",
                            getMonth(4).text
                        );
                        //setFirstLastDay(getMonth(4).month, getMonth(4).year);
                        setFrom(
                            getFrom(getMonth(4).month, getMonth(4).year)
                        );
                        setTo(getTo(getMonth(4).month, getMonth(4).year));
                        sessionStorage.setItem(
                            "from",
                            getFrom(getMonth(4).month, getMonth(4).year)
                        );
                        sessionStorage.setItem(
                            "to",
                            getTo(getMonth(4).month, getMonth(4).year)
                        );

                        setLoadDataComplete(false);
                        setItems([]);
                        setGetNewDocuments(true);

                        setTotalDocuments(0);
                        setTotalVat(0);
                        setTotalGrand(0);

                        setCurrentPage(1);

                        resetSort();
                    },
                    //['data-automation-id']: 'newEmailButton', // optional
                },
                {
                    key: getMonth(5).year + "" + getMonth(5).key,
                    text: getMonth(5).text,
                    iconProps: { iconName: "Calendar" },
                    onClick: () => {
                        console.log(getMonth(5).key);
                        setQueryMonth({
                            key: getMonth(5).year + "" + getMonth(5).key,
                            text: getMonth(5).text,
                        });
                        sessionStorage.setItem(
                            "queryMonth-key",
                            getMonth(5).year + "" + getMonth(5).key
                        );
                        sessionStorage.setItem(
                            "queryMonth-text",
                            getMonth(5).text
                        );
                        //setFirstLastDay(getMonth(5).month, getMonth(5).year);
                        setFrom(
                            getFrom(getMonth(5).month, getMonth(5).year)
                        );
                        setTo(getTo(getMonth(5).month, getMonth(5).year));
                        sessionStorage.setItem(
                            "from",
                            getFrom(getMonth(5).month, getMonth(5).year)
                        );
                        sessionStorage.setItem(
                            "to",
                            getTo(getMonth(5).month, getMonth(5).year)
                        );

                        setLoadDataComplete(false);
                        setItems([]);
                        setGetNewDocuments(true);

                        setTotalDocuments(0);
                        setTotalVat(0);
                        setTotalGrand(0);

                        setCurrentPage(1);

                        resetSort();
                    },
                    //['data-automation-id']: 'newEmailButton', // optional
                },
            ],
        },
    }, 
    */
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: "Calendar" },
      disabled: !loadDataComplete || isProcessDocument,
      subMenuProps: {
        items: [
          {
            key: "queryMonth",
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Stack>
                <Stack
                  horizontal
                  styles={{
                    root: { height: 42, paddingTop: 10 },
                  }}
                >
                  <Stack
                    styles={{
                      root: {
                        width: "20%",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    }}
                  >
                    <Text>จาก</Text>
                  </Stack>
                  <Stack
                    styles={{
                      root: {
                        width: "80%",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DatePicker
                      styles={{ root: { width: "90%" } }}
                      placeholder="กรุณาเลือก"
                      strings={DayPickerStrings}
                      value={fromDate}
                      maxDate={toDate}
                      formatDate={onFormatDate}
                      onSelectDate={(_date) => {
                        setFromDate(_date);

                        // let adjustDate = _date;

                        // setFrom2(getFrom3(adjustDate.getDate(), adjustDate.getMonth(), adjustDate.getFullYear()));

                        setTimeRangeError(false);
                        if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack
                  horizontal
                  styles={{
                    root: { height: 42, paddingTop: 10 },
                  }}
                >
                  <Stack
                    styles={{
                      root: {
                        width: "20%",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    }}
                  >
                    <Text>ถึง</Text>
                  </Stack>
                  <Stack styles={{ root: { width: "80%" } }}>
                    <DatePicker
                      styles={{ root: { width: "90%" } }}
                      placeholder="กรุณาเลือก"
                      strings={DayPickerStrings}
                      value={toDate}
                      minDate={fromDate}
                      formatDate={onFormatDate}
                      onSelectDate={(_date) => {
                        setToDate(_date);

                        // let adjustDate = _date;

                        // setTo2(getTo3(adjustDate.getDate(), adjustDate.getMonth(), adjustDate.getFullYear()));
                        setTimeRangeError(false);
                        if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                      }}
                    />
                  </Stack>
                </Stack>
                {timeRangeError ? (
                  <Stack
                    styles={{
                      root: {
                        padding: "12px 0px 0px 12px",
                      },
                    }}
                  >
                    <Text
                      styles={{
                        root: {
                          fontSize: 12,
                          color: "#A4262C",
                        },
                      }}
                    >
                      กรุณาระบุช่วงเวลาไม่เกิน 30 วัน
                    </Text>
                  </Stack>
                ) : null}
                <Stack
                  horizontal
                  horizontalAlign="end"
                  styles={{
                    root: {
                      width: "92%",
                      height: 52,
                      padding: "10px 0px 10px 0px",
                    },
                  }}
                >
                  <PrimaryButton
                    text="ค้นหา"
                    onClick={() => {
                      if (!timeRangeError) {
                        setQueryMonth({
                          type: "dateRange",
                          key: formatDate(fromDate) + ":" + formatDate(toDate),
                          text: formatDate(fromDate) + " - " + formatDate(toDate),
                        });

                        sessionStorage.setItem("uploadXml-queryMonth-key", formatDate(fromDate) + ":" + formatDate(toDate));
                        sessionStorage.setItem("uploadXml-queryMonth-text", formatDate(fromDate) + " - " + formatDate(toDate));

                        setFrom(getFrom(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                        setTo(getTo(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                        sessionStorage.setItem("uploadXml-from", getFrom(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                        sessionStorage.setItem("uploadXml-to", getTo(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                        // setFrom2(getFrom2(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                        // setTo2(getTo2(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                        sessionStorage.setItem("uploadXml-from2", getFrom2(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                        sessionStorage.setItem("uploadXml-to2", getTo2(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                        sessionStorage.setItem("uploadXml-fromDate", fromDate.toISOString());
                        sessionStorage.setItem("uploadXml-toDate", toDate.toISOString());

                        setSearch("");

                        setLoadDataComplete(false);
                        setItems([]);
                        setGetNewDocuments(true);

                        setTotalDocuments(0);

                        setCurrentPage(1);

                        setTotalPending(0);
                        setTotalSpPending(0);
                        setTotalFail(0);
                        setTotalSuccessful(0);
                        setTotalNotYetDeliveries(0);
                      }
                    }}
                    style={{
                      width: "125px",
                      letterSpacing: "1px",
                    }}
                  />
                </Stack>
              </Stack>
            </div>
          );
        },
      },
    },
    /* 
            {
                key: pageSize,
                text: pageSize + " เอกสาร/หน้า",
                cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
                iconProps: { iconName: "NumberedList" },
                disabled: !loadDataComplete || isProcessDocument,
                subMenuProps: {
                    items: [
                        {
                            key: 10,
                            text: "10 เอกสาร/หน้า",
                            iconProps: { iconName: "NumberedList" },
                            onClick: () => {
                                console.log("10 เอกสาร/หน้า");
                                setPageSize(10);
                                sessionStorage.setItem("uploadXml-pageSize", 10);
    
                                setLoadDataComplete(false);
                                setItems([]);
                                setGetNewDocuments(true);
    
                                setTotalDocuments(0);
                                setTotalVat(0);
                                setTotalGrand(0);
                            },
                        },
                        {
                            key: 20,
                            text: "20 เอกสาร/หน้า",
                            iconProps: { iconName: "NumberedList" },
                            onClick: () => {
                                console.log("20 เอกสาร/หน้า");
                                setPageSize(20);
                                sessionStorage.setItem("uploadXml-pageSize", 20);
    
                                setLoadDataComplete(false);
                                setItems([]);
                                setGetNewDocuments(true);
    
                                setTotalDocuments(0);
                                setTotalVat(0);
                                setTotalGrand(0);
                            },
                        },
                        {
                            key: 30,
                            text: "30 เอกสาร/หน้า",
                            iconProps: { iconName: "NumberedList" },
                            onClick: () => {
                                console.log("30 เอกสาร/หน้า");
                                setPageSize(30);
                                sessionStorage.setItem("uploadXml-pageSize", 30);
    
                                setLoadDataComplete(false);
                                setItems([]);
                                setGetNewDocuments(true);
    
                                setTotalDocuments(0);
                                setTotalVat(0);
                                setTotalGrand(0);
                            },
                        },
                        {
                            key: 40,
                            text: "40 เอกสาร/หน้า",
                            iconProps: { iconName: "NumberedList" },
                            onClick: () => {
                                console.log("40 เอกสาร/หน้า");
                                setPageSize(40);
                                sessionStorage.setItem("uploadXml-pageSize", 40);
    
                                setLoadDataComplete(false);
                                setItems([]);
                                setGetNewDocuments(true);
    
                                setTotalDocuments(0);
                                setTotalVat(0);
                                setTotalGrand(0);
                            },
                        },
                        {
                            key: 50,
                            text: "50 เอกสาร/หน้า",
                            iconProps: { iconName: "NumberedList" },
                            onClick: () => {
                                console.log("50 เอกสาร/หน้า");
                                setPageSize(50);
                                sessionStorage.setItem("uploadXml-pageSize", 50);
    
                                setLoadDataComplete(false);
                                setItems([]);
                                setGetNewDocuments(true);
    
                                setTotalDocuments(0);
                                setTotalVat(0);
                                setTotalGrand(0);
                            },
                        },
                        {
                            key: 100,
                            text: "100 เอกสาร/หน้า",
                            iconProps: { iconName: "NumberedList" },
                            onClick: () => {
                                console.log("100 เอกสาร/หน้า");
                                setPageSize(100);
                                sessionStorage.setItem("uploadXml-pageSize", 100);
    
                                setLoadDataComplete(false);
                                setItems([]);
                                setGetNewDocuments(true);
    
                                setTotalDocuments(0);
                                setTotalVat(0);
                                setTotalGrand(0);
                            },
                        },
                    ],
                },
            }, 
            */
    {
      key: currentPage,
      text: "หน้า " + currentPage,
      iconProps: { iconName: "Page" },
      disabled: !loadDataComplete || isProcessDocument,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    /* 
    {
        key: "download",
        text: "ดาวน์โหลด",
        iconProps: { iconName: "Download" },
        disabled: !loadDataComplete || isProcessDocument,
        onClick: () => {
            console.log("Download");
            downloadZip();
        },
    },
    
    {
        key: "delete",
        text: "ลบ",
        iconProps: { iconName: "Delete" },
        disabled:
            !loadDataComplete ||
            isProcessDocument ||
            selectedItems.length == 0 ||
            !isAdmin,
        onClick: () => {
            console.log("Delete");
            console.log("selectionItems: ", selectedItems);

            let confirm = window.confirm(
                "กรุณายืนยัน การลบเอกสารที่เลือก จำนวน " +
                selectedItems.length +
                " เอกสาร"
            );

            if (confirm && isAdmin) {
                setIsProcessDocument(true);
                deleteSelectedDocuments();
            }
            /* 
            setLoadDataComplete(false);
            setDocuments([]);
            setItems([]);
            setGetNewDocuments(true);

            setTotalDocuments(0);
            setTotalVat(0);
            setTotalGrand(0);
*
        },
    },
     
    {
        key: "recreate-xml",
        text: "สร้าง XML ใหม่",
        iconProps: { iconName: "RepeatAll" },
        disabled: !loadDataComplete || isProcessDocument || selectedItems.length == 0 || !isAdmin,
        onClick: () => {
            console.log("Recreate XML");

            console.log("selectionItems: ", selectedItems);

            let confirm = window.confirm("กรุณายืนยัน การสร้างไฟล์ XML ใหม่, เอกสารที่เลือก จำนวน " + selectedItems.length + " เอกสาร");

            if (confirm && isAdmin) {
                setIsProcessDocument(true);
                reCreateXmlSelectedDocuments();

                
            }
        },
    },
*/
    {
      key: "refresh",
      text: "รีเฟรช",
      iconProps: { iconName: "Refresh" },
      disabled: !loadDataComplete || isProcessDocument,
      onClick: () => {
        console.log("Refresh");

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        // setCurrentPage(1);

        // resetSort();
      },
    },
  ];

  let commandBarItems2 = [
    {
      key: "recreate-xml",
      text: "สร้าง XML ใหม่",
      iconProps: { iconName: "RepeatAll" },
      disabled: !loadDataComplete || isProcessDocument || selectedItems.length === 0 || !isAdmin,
      onClick: () => {
        console.log("Recreate XML");

        console.log("selectionItems: ", selectedItems);

        let confirm = window.confirm("กรุณายืนยัน การสร้างไฟล์ XML ใหม่, เอกสารที่เลือก จำนวน " + selectedItems.length + " เอกสาร");

        if (confirm && isAdmin) {
          setIsProcessDocument(true);
          reCreateXmlSelectedDocuments();


        }
      },
    }
  ];


  // const [commandBarItems, setCommandBarItems] = useState(_commandBarItems);

  const toThaiDateString = (isoDateTime) => {
    let date = "";
    date = new Date(isoDateTime);

    //console.log(dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString());
    /* 
  let monthNames = [
    "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
    "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม.",
    "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
  ];
*/
    let year = date.getFullYear() + 543;
    //let month = monthNames[date.getMonth()];
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let numOfDay = date.getDate().toString().padStart(2, "0");

    let hour = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    let second = date.getSeconds().toString().padStart(2, "0");

    return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  /* 
  useEffect(() => {
    if (account) {
        instance.acquireTokenSilent({
            scopes: ["User.Read"],
            account: account
        }).then((response) => {
            if(response) {
                callMsGraph(response.accessToken).then((result) => setApiData(result));
            }
        });
    }
  }, [account, instance]);
       */

  const initial = async () => {
    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        console.log("tokenResponse: ", tokenResponse);

        await axios
          .get(ADMIN_API_URL + "/roles", {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data && response.data.roles) {
                // setRole(response.data.roles);

                if (response.data.roles === "admin") {
                  setIsAdmin(true);
                  // console.log('remove array: ', removeByAttr(commandBarItems, 'key', 'delete'));
                } else {
                  setIsAdmin(false);
                }
              } else {
                history.push({
                  pathname: "/maintenance",
                  state: {},
                });
              }

              // setIsLoadRolesComplete(true);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    console.log("userEffect initial Call!");

    initial();

    //console.log('UTC now: ', convertDateToUTC());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log("userEffect getNewDocuments Call!");

    if (getNewDocuments) {
      setShowRepeatNumber(false);
      setDuplicatedNumbers([]);

      let documentsListEndpoint =
        ADMIN_API_URL +
        // 'http://localhost:7071/api' +
        "/etax/xml/deliveries/" +
        documentType.key +
        "/?from=" +
        from +
        "&to=" +
        to +
        "&date=" +
        dateType.key +
        "&offset=" +
        (currentPage - 1) * pageSize +
        "&limit=" +
        pageSize +
        "&deleted=false" +
        "&companiesId=" +
        companiesFilter.key +
        "&rdStatus=" +
        rdStatusType.key +
        "&sortDirection=" +
        sortDirection +
        "&sortBy=" +
        primarySort;
      let documentsSummaryEndpoint = ADMIN_API_URL + "/etax/xml/deliveries/summaries/" + documentType.key + "/?from=" + from + "&to=" + to + "&date=" + dateType.key + "&deleted=false&companiesId=" + companiesFilter.key + "&rdStatus=" + rdStatusType.key;
      // let documentsSummaryEndpoint = 'http://localhost:7071/api' + '/etax/xml/deliveries/summaries/' + documentType.key + '/?from=' + from + '&to=' + to + '&date=' + dateType.key + '&deleted=false&companiesId=' + companiesFilter.key + "&rdStatus=" + rdStatusType.key;
      let documentsCompaniesNameEndpoint = ADMIN_API_URL + "/companies/name/" + documentType.key + "/xml?from=" + from + "&to=" + to + "&date=" + dateType.key + "&deleted=" + deletedStatus.key + "&rdStatus=" + rdStatusType.key;
      // let documentsCompaniesNameEndpoint = 'http://localhost:7071/api' + '/companies/name/' + documentType.key + '/xml?from=' + from + '&to=' + to + '&date=' + dateType.key + '&deleted=' + deletedStatus.key + "&rdStatus=" + rdStatusType.key;;

      if (search) {
        documentsListEndpoint =
          ADMIN_API_URL +
          // 'http://localhost:7071/api' +
          "/documents/search/" +
          documentType.key +
          "/xml?from=" +
          from +
          "&to=" +
          to +
          "&date=" +
          dateType.key +
          "&offset=" +
          (currentPage - 1) * pageSize +
          "&limit=" +
          pageSize +
          "&search=" +
          search +
          "&deleted=" +
          deletedStatus.key +
          "&companiesId=" +
          companiesFilter.key +
          "&rdStatus=" +
          rdStatusType.key +
          "&sortDirection=" +
          sortDirection +
          "&sortBy=" +
          primarySort;
        documentsSummaryEndpoint =
          ADMIN_API_URL +
          // 'http://localhost:7071/api' +
          "/documents/summaries/search/" +
          documentType.key +
          "/xml?from=" +
          from +
          "&to=" +
          to +
          "&date=" +
          dateType.key +
          "&search=" +
          search +
          "&deleted=" +
          deletedStatus.key +
          "&companiesId=" +
          companiesFilter.key +
          "&rdStatus=" +
          rdStatusType.key;

        documentsCompaniesNameEndpoint = ADMIN_API_URL + "/companies/name/" + documentType.key + "/xml?from=" + from + "&to=" + to + "&date=" + dateType.key + "&search=" + search + "&deleted=" + deletedStatus.key + "&rdStatus=" + rdStatusType.key;
      }

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          // axios
          //   .get('http://localhost:7071/api/DocumentsTotal', {
          //     headers: {
          //       Authorization: 'Bearer ' + tokenResponse.accessToken,
          //     },
          //     params: {
          //       documentType: documentType.key,
          //       from: from,
          //       to: to,
          //       dateType: dateType.key,
          //       accessToken: tokenResponse.accessToken,
          //     },
          //   })
          //   .then((response) => {
          //     console.log('Documents summary:', response);

          //     const { countTotal, totalGrand, totalVat } = response.data;

          //     setTotalDocuments(countTotal);
          //     setTotalGrand(totalGrand);
          //     setTotalVat(totalVat);
          //   })
          //   .catch((error) => console.log(error));

          let _companiesFilterItems = [];

          axios
            .get(documentsSummaryEndpoint, {
              headers: {
                Authorization: "Bearer " + tokenResponse.accessToken,
              },
            })
            .then((response) => {
              console.log("Documents summary:", response);

              const { countTotal, totalGrand, totalVat } = response.data;
              const { notYetDeliveredTotal, pendingTotal, spPendingTotal, failTotal, successfulTotal } = response.data.deliveries.rd;

              setTotalDocuments(countTotal);
              setTotalGrand(totalGrand);
              setTotalVat(totalVat);

              setTotalNotYetDeliveries(notYetDeliveredTotal);
              setTotalPending(pendingTotal);
              setTotalSpPending(spPendingTotal);
              setTotalFail(failTotal);
              setTotalSuccessful(successfulTotal);
            })
            .catch((error) => console.log(error));

          axios
            .get(documentsCompaniesNameEndpoint, {
              headers: {
                Authorization: "Bearer " + tokenResponse.accessToken,
              },
            })
            .then((response) => {
              console.log("Documents companies name:", response);

              let _companiesName = response.data;

              if (_companiesName.length > 0) {
                _companiesFilterItems.push({
                  key: "allCompanies",
                  text: `บริษัททั้งหมด (${_companiesName.length})`,
                  iconProps: { iconName: "CityNext2" },
                  onClick: () => {
                    console.log("บริษัททั้งหมด");
                    setCompaniesFilter({
                      key: "allCompanies",
                      text: `บริษัททั้งหมด (${_companiesName.length})`,
                    });
                    sessionStorage.setItem("uploadXml-companiesFilter-key", "allCompanies");
                    sessionStorage.setItem("uploadXml-companiesFilter-text", "บริษัททั้งหมด");

                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);

                    setTotalDocuments(0);
                    setTotalVat(0);
                    setTotalGrand(0);
                  },
                  //['data-automation-id']: 'newEmailButton', // optional
                });

                if (companiesFilter.key === "allCompanies") {
                  setCompaniesFilter({
                    key: "allCompanies",
                    text: `บริษัททั้งหมด (${_companiesName.length})`,
                  });
                }

                _companiesName = _companiesName.sort((a, b) => {
                  if (a.countTotal > b.countTotal) {
                    return -1;
                  } else {
                    return 0;
                  }
                });

                _companiesName.forEach((_doc) => {
                  _companiesFilterItems.push({
                    key: _doc.id,
                    // text: _doc.name + " (" + _doc.countTotal + ")",
                    text: _doc.name + " " + _doc.branchNumber + " (" + _doc.countTotal + ")",
                    iconProps: {
                      iconName: "CityNext2",
                    },
                    onClick: () => {
                      // console.log(_doc.name + " (" + _doc.countTotal + ")");
                      console.log(_doc.name + " " + _doc.branchNumber + " (" + _doc.countTotal + ")");
                      setCompaniesFilter({
                        key: _doc.id,
                        // text: _doc.name + " (" + _doc.countTotal + ")",
                        text: _doc.name + " " + _doc.branchNumber + " (" + _doc.countTotal + ")",
                      });

                      sessionStorage.setItem("uploadXml-companiesFilter-key", _doc.id);
                      // sessionStorage.setItem("uploadXml-companiesFilter-text", _doc.name + " (" + _doc.countTotal + ")");
                      sessionStorage.setItem("uploadXml-companiesFilter-text", _doc.name + " " + _doc.branchNumber + " (" + _doc.countTotal + ")");

                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setTotalDocuments(0);
                      setTotalVat(0);
                      setTotalGrand(0);

                      setCurrentPage(1);

                      uploadingXmlStop.current = false;

                      // setEtaxUserLastLogin("");
                      // setUploadingXmlStop(false);
                      setUploadingDocumentId("");
                      setUploadingDocumentNumber("");
                      setUploadingDocumentStatus("");
                      setUploadingDocumentMessage("");
                      setUploadingDocumentRefNo("");
                      setUploadingDocumentFileName("");
                    },
                    //['data-automation-id']: 'newEmailButton', // optional
                  });
                });

                setCompaniesFilterItems(_companiesFilterItems);

                console.log("_companiesFilterItems: ", _companiesFilterItems);
              }

              // const { countTotal, totalGrand, totalVat } = response.data;

              // setTotalDocuments(countTotal);
              // setTotalGrand(totalGrand);
              // setTotalVat(totalVat);
            })
            .catch((error) => console.log(error));

          const [response] = await Promise.all([
            axios
              .get(documentsListEndpoint, {
                headers: {
                  Authorization: "Bearer " + tokenResponse.accessToken,
                },
              })
              .catch((error) => console.log(error)),
          ]);

          console.log("response pdf", response);

          let _documents = response.data.data.documents;
          let _totalDocument = response.data.data.countTotal;

          _documents.forEach((doc) => {
            if (doc.type === "CREDIT-NOTE") {
              doc.data.amountTotal = -Math.abs(doc.data.amountTotal);
              doc.data.grandTotal = -Math.abs(doc.data.grandTotal);
              doc.data.vatTotal = -Math.abs(doc.data.vatTotal);
            }
          });

          //Page dropdown
          let _pageDropdown = [];

          for (let i = 1; i <= Math.ceil(_totalDocument / pageSize); i++) {
            _pageDropdown.push({
              key: i,
              text: "หน้า " + i,
              iconProps: { iconName: "Page" },
              onClick: () => {
                console.log("Selected page: " + i);
                setCurrentPage(i);

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);
              },
            });
          }

          setPageDropdown(_pageDropdown);

          if (currentPage > Math.ceil(_totalDocument / pageSize)) {
            setCurrentPage(1);
          }
          /* 
                              //Sorting
                              if (_documents && _documents.length > 0) {
                                  if (primarySort === "createdTime") {
                                      if (isSortedDescendingCreatedTime) {
                                          _documents.sort(function (a, b) {
                                              // Turn your strings into dates, and then subtract them
                                              // to get a value that is either negative, positive, or zero.
                                              return new Date(b.createdTime) - new Date(a.createdTime);
                                          });
                                      } else {
                                          _documents.sort(function (a, b) {
                                              // Turn your strings into dates, and then subtract them
                                              // to get a value that is either negative, positive, or zero.
                                              return new Date(a.createdTime) - new Date(b.createdTime);
                                          });
                                      }
                                  } else if (primarySort === "number") {
                                      if (isSortedDescendingNumber) {
                                          _documents.sort((a, b) => {
                                              return /[A-Za-z]/.test(b.data.number) - /[A-Za-z]/.test(a.data.number) || (b.data.number.toUpperCase() < a.data.number.toUpperCase() ? -1 : b.data.number.toUpperCase() > a.data.number.toUpperCase() ? 1 : 0);
                                          });
                                      } else {
                                          _documents.sort((a, b) => {
                                              return /[A-Za-z]/.test(a.data.number) - /[A-Za-z]/.test(b.data.number) || (a.data.number.toUpperCase() < b.data.number.toUpperCase() ? -1 : a.data.number.toUpperCase() > b.data.number.toUpperCase() ? 1 : 0);
                                          });
                                      }
                                  } else if (primarySort === "documentDate") {
                                      if (isSortedDescendingDocumentDate) {
                                          _documents.sort(function (a, b) {
                                              // Turn your strings into dates, and then subtract them
                                              // to get a value that is either negative, positive, or zero.
                                              return new Date(b.data.date) - new Date(a.data.date);
                                          });
                                      } else {
                                          _documents.sort(function (a, b) {
                                              // Turn your strings into dates, and then subtract them
                                              // to get a value that is either negative, positive, or zero.
                                              return new Date(a.data.date) - new Date(b.data.date);
                                          });
                                      }
                                  } else if (primarySort === "customerName") {
                                      if (isSortedDescendingCustomerName) {
                                          _documents.sort((a, b) => {
                                              if (b.data.customer.name < a.data.customer.name) {
                                                  return -1;
                                              }
                                              if (b.data.customer.name > a.data.customer.name) {
                                                  return 1;
                                              }
                                              return 0;
                                          });
                                      } else {
                                          _documents.sort((a, b) => {
                                              if (a.data.customer.name < b.data.customer.name) {
                                                  return -1;
                                              }
                                              if (a.data.customer.name > b.data.customer.name) {
                                                  return 1;
                                              }
                                              return 0;
                                          });
                                      }
                                  } else if (primarySort === "customerPhone") {
                                      if (isSortedDescendingCustomerPhone) {
                                          _documents.sort((a, b) => {
                                              if (b.data.customer.phone < a.data.customer.phone) {
                                                  return -1;
                                              }
                                              if (b.data.customer.phone > a.data.customer.phone) {
                                                  return 1;
                                              }
                                              return 0;
                                          });
                                      } else {
                                          _documents.sort((a, b) => {
                                              if (a.data.customer.phone < b.data.customer.phone) {
                                                  return -1;
                                              }
                                              if (a.data.customer.phone > b.data.customer.phone) {
                                                  return 1;
                                              }
                                              return 0;
                                          });
                                      }
                                  } else if (primarySort === "vatTotal") {
                                      if (isSortedDescendingVatTotal) {
                                          _documents.sort(function (a, b) {
                                              return b.data.vatTotal - a.data.vatTotal;
                                          });
                                      } else {
                                          _documents.sort(function (a, b) {
                                              return a.data.vatTotal - b.data.vatTotal;
                                          });
                                      }
                                  } else if (primarySort === "grandTotal") {
                                      if (isSortedDescendingGrandTotal) {
                                          _documents.sort(function (a, b) {
                                              return b.data.grandTotal - a.data.grandTotal;
                                          });
                                      } else {
                                          _documents.sort(function (a, b) {
                                              return a.data.grandTotal - b.data.grandTotal;
                                          });
                                      }
                                  } else if (primarySort === "name") {
                                      if (isSortedDescendingName) {
                                          _documents.sort((a, b) => {
                                              if (b.data.name < a.data.name) {
                                                  return -1;
                                              }
                                              if (b.data.name > a.data.name) {
                                                  return 1;
                                              }
                                              return 0;
                                          });
                                      } else {
                                          _documents.sort((a, b) => {
                                              if (a.data.name < b.data.name) {
                                                  return -1;
                                              }
                                              if (a.data.name > b.data.name) {
                                                  return 1;
                                              }
                                              return 0;
                                          });
                                      }
                                  } else if (primarySort === "emailReceiver") {
                                      if (isSortedDescendingEmailReceiver) {
                                          _documents.sort((a, b) => {
                                              if (b.data.customer.email < a.data.customer.email) {
                                                  return -1;
                                              }
                                              if (b.data.customer.email > a.data.customer.email) {
                                                  return 1;
                                              }
                                              return 0;
                                          });
                                      } else {
                                          _documents.sort((a, b) => {
                                              if (a.data.customer.email < b.data.customer.email) {
                                                  return -1;
                                              }
                                              if (a.data.customer.email > b.data.customer.email) {
                                                  return 1;
                                              }
                                              return 0;
                                          });
                                      }
                                  } else if (primarySort === "emailTime") {
                                      if (isSortedDescendingEmailTime) {
                                          _documents.sort(function (a, b) {
                                              // Turn your strings into dates, and then subtract them
                                              // to get a value that is either negative, positive, or zero.
                                              return new Date(b.deliveries.email.time) - new Date(a.deliveries.email.time);
                                          });
                                      } else {
                                          _documents.sort(function (a, b) {
                                              // Turn your strings into dates, and then subtract them
                                              // to get a value that is either negative, positive, or zero.
                                              return new Date(a.deliveries.email.time) - new Date(b.deliveries.email.time);
                                          });
                                      }
                                  }
                              }
           */
          //set page
          setPageData(paginate(_totalDocument, currentPage, pageSize, 10));

          let numberArray = _documents.map(function (doc) {
            return doc.data.number;
          });
          let isDuplicate = numberArray.some(function (number, idx) {
            return numberArray.indexOf(number) !== idx;
          });

          if (isDuplicate) {
            const _duplicatedNumbers = numberArray.filter((item, index) => index !== numberArray.indexOf(item));
            const uniq = [...new Set(_duplicatedNumbers)];
            setDuplicatedNumbers(uniq)
          }
          setShowRepeatNumber(isDuplicate);
          // setUploadAble(!isDuplicate)

          let _docs = [];

          if (_documents.length > 0) {
            for (let i = 0; i < _documents.length; i++) {
              let fileSize = formatBytes(_documents[i].file.size);
              let createdTime = toThaiDateString(_documents[i].createdTime);

              let rdDeliveredTime = "-";
              if (_documents[i].deliveries.rd.time) {
                rdDeliveredTime = toThaiDateString(_documents[i].deliveries.rd.time);
              }

              let rdRefNumber = "-";
              if (_documents[i].deliveries.rd.refNumber) {
                rdRefNumber = _documents[i].deliveries.rd.refNumber;
              }

              let _companyEmail = "";

              let _customerEmail = "";
              let _customerPhone = "";
              let _amountTotal = 0;
              let _vatTotal = 0;
              let _grandTotal = 0;

              if (_documents[i].data.company && _documents[i].data.company.email && _documents[i].data.company.email !== null && _documents[i].data.company.email !== undefined) {
                _companyEmail = _documents[i].data.company.email;
              }

              if (_documents[i].data.customer && _documents[i].data.customer.email && _documents[i].data.customer.email !== null && _documents[i].data.customer.email !== undefined) {
                _customerEmail = _documents[i].data.customer.email;
              }

              if (_documents[i].data.customer && _documents[i].data.customer.phone && _documents[i].data.customer.phone !== null && _documents[i].data.customer.phone !== undefined) {
                _customerPhone = _documents[i].data.customer.phone;
              }

              if (_documents[i].type === "WITHHOLDING-TAX") {
                _amountTotal = "-";
                _vatTotal = "-";
                _grandTotal = "-";
              } else {
                if (_documents[i].data.amountTotal) {
                  _amountTotal = _documents[i].data.amountTotal;
                }

                if (_documents[i].data.vatTotal) {
                  _vatTotal = _documents[i].data.vatTotal;
                }

                if (_documents[i].data.grandTotal) {
                  _grandTotal = _documents[i].data.grandTotal;
                }
              }

              let _isPdfXml = false;

              if (_documents[i].xmlFile && _documents[i].xmlFile.size) {
                _isPdfXml = true;
              }

              /* 
                                          let _rdStatus = "-";
              
                                          if (
                                              _documents[i].deliveries.rd.status ===
                                              "pending"
                                          ) {
                                              _rdStatus = "รอส่งอัตโนมัติ";
                                          } else if (
                                              _documents[i].deliveries.rd.status ===
                                              "successful"
                                          ) {
                                              _rdStatus = "สำเร็จ";
                                          } else if (
                                              _documents[i].deliveries.rd.status ===
                                              "fail"
                                          ) {
                                              _rdStatus = "ไม่สำเร็จ";
                                          }
              
              let _rdStatus = "-";

              if (_documents[i].deliveries.rd.status === "pending") {
                  _rdStatus = "รอส่งอัตโนมัติ";
              } else if (_documents[i].deliveries.rd.status === "successful") {
                  _rdStatus = "นำส่งสำเร็จ";
              } else if (_documents[i].deliveries.rd.status === "fail") {
                  _rdStatus = "นำส่งไม่สำเร็จ";
              }
*/
              let _rdStatus = "-";

              if (_documents[i].deliveries.rd.status === "pending") {
                _rdStatus = "-";
              } else if (_documents[i].deliveries.rd.status === "") {
                _rdStatus = "-";
              } else if (_documents[i].deliveries.rd.status === null) {
                _rdStatus = "-";
              } else if (_documents[i].deliveries.rd.status === "sp-pending") {
                _rdStatus = "นำส่งแล้ว";
              } else if (_documents[i].deliveries.rd.status === "successful") {
                _rdStatus = "นำส่งสำเร็จ";
              } else if (_documents[i].deliveries.rd.status === "fail") {
                _rdStatus = "นำส่งไม่สำเร็จ";
              }

              let _rdStatusResponse = "-";

              if (_documents[i].deliveries && _documents[i].deliveries.rd && _documents[i].deliveries.rd.response) {
                if (_documents[i].deliveries.rd.response.status === true) {
                  _rdStatusResponse = "สำเร็จ";
                } else if (_documents[i].deliveries.rd.response.status === false) {
                  _rdStatusResponse = "ไม่สำเร็จ";
                }
              }

              /* 
                                          let _deleted = "-";
              
                                          if (_documents[i].deleted) {
                                              _deleted = "ลบ"
                                          }
               */
              _docs.push({
                key: _documents[i].id,
                pdfFileName: _documents[i].file.displayName,
                name: _documents[i].data.name,
                fileExtension: _documents[i].file.extension,
                isPdfXml: _isPdfXml,
                createdBy: _documents[i].createdBy,
                createdTime: createdTime,
                createdTimeValue: _documents[i].createdTime,
                fileSize: fileSize,
                fileSizeRaw: _documents[i].file.size,
                type: _documents[i].type,
                signature: _documents[i].signatures[0].certificateName,
                product: _documents[i].product,
                number: _documents[i].data.number,
                companyEmail: _companyEmail,
                customerName: _documents[i].data.customer.name,
                customerEmail: _customerEmail,
                customerPhone: _customerPhone,
                amountTotal: _amountTotal,
                vatTotal: _vatTotal,
                grandTotal: _grandTotal,
                data: _documents[i].data,
                documentDate: _documents[i].data.dateBE,
                xmlData: _documents[i].xmlFile,
                // rdStatus: _documents[i].deliveries.rd.status,
                digitalSign: _documents[i].digitalSign,
                reIssue: _documents[i].data.reIssue,
                emailReceiver: _documents[i]?.deliveries?.email?.receiver ? _documents[i].deliveries.email.receiver : _documents[i].data.customer.email,
                emailTime: _documents[i]?.deliveries?.email?.time ? toThaiDateString(_documents[i].deliveries.email.time) : "",
                emailTimeValue: _documents[i]?.deliveries?.email?.time,
                documentDateValue: _documents[i].data.date,
                smsTime: _documents[i]?.deliveries?.sms?.response?.date_created ? toThaiDateString(_documents[i]?.deliveries?.sms?.response?.date_created) : "",
                smsTimeValue: _documents[i]?.deliveries?.sms?.response?.date_created,
                // rdStatus: _rdStatus,
                companyName: _documents[i].data.company.name,
                deleted: _documents[i].deleted,
                rdStatus: _rdStatus,
                rdStatusValue: _documents[i]?.deliveries?.rd?.status,
                rdStatusResponse: _rdStatusResponse,
                rdDeliveredTime: rdDeliveredTime,
                rdDeliveredTimeValue: _documents[i].deliveries.rd.time,
                rdRefNumber: rdRefNumber,
                isUploadXml: false,
              });
            }
          }

          console.log("Docs data", _docs);
          setItems(_docs);

          setLoadDataComplete(true);
          setGetNewDocuments(false);
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          // instance.acquireTokenRedirect(silentRequest);
        });
    }
    // eslint-disable-next-line
  }, [getNewDocuments]);

  useEffect(() => {
    if (documents.length > 0) {
      console.log("currentPage useEffect");

      const _items = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
        // let documentName = "";
        //let fileSize = formatBytes(documents[i].fileSize);
        let fileSize = formatBytes(documents[i].file.size);
        let createdTime = toThaiDateString(documents[i].createdTime);

        //let certificateId = documents[i].certificateId;
/* 
        //if (documents[i].type === 'ETAX') {
        if (documents[i].product === "etax") {
          documentName = documents[i].data.name + " " + documents[i].data.number;
        }
 */
        let _amountTotal = 0;
        let _vatTotal = 0;
        let _grandTotal = 0;

        if (documents[i].type === "WITHHOLDING-TAX") {
          _amountTotal = "-";
          _vatTotal = "-";
          _grandTotal = "-";
        } else {
          if (documents[i].data.amountTotal) {
            _amountTotal = documents[i].data.amountTotal;
          }

          if (documents[i].data.vatTotal) {
            _vatTotal = documents[i].data.vatTotal;
          }

          if (documents[i].data.grandTotal) {
            _grandTotal = documents[i].data.grandTotal;
          }
        }

        let _rdStatus = "-";

        if (documents[i].deliveries.rd.status === "pending") {
          _rdStatus = "รอส่งอัตโนมัติ";
        } else if (documents[i].deliveries.rd.status === "successful") {
          _rdStatus = "สำเร็จ";
        } else if (documents[i].deliveries.rd.status === "fail") {
          _rdStatus = "ไม่สำเร็จ";
        }

        let _isPdfXml = false;

        if (documents[i].xmlFile && documents[i].xmlFile.size) {
          _isPdfXml = true;
        }

        _items.push({
          key: documents[i].id,
          //fileName: documents[i].fileName + '.' + documents[i].fileExtension,
          pdfFileName: documents[i].file.name + "." + documents[i].file.extension,
          //name: documentName,
          name: documents[i].data.name,
          number: documents[i].data.number,
          //value: documents[i].fileName,
          value: documents[i].file.name,
          //iconName: 'https://static2.sharepointonline.com/files/fabric/assets/item-types/16/xml.svg',
          //fileExtension: documents[i].fileExtension,
          fileExtension: documents[i].file.extension,
          isPdfXml: _isPdfXml,
          createdBy: documents[i].createdBy,
          createdTime: createdTime,
          createdTimeValue: documents[i].createdTime,
          fileSize: fileSize,
          //fileSizeRaw: documents[i].fileSize,
          fileSizeRaw: documents[i].file.size,
          type: documents[i].type,
          signature: documents[i].signatures[0].certificateName,
          product: documents[i].product,
          customerName: documents[i].data.customer.name,
          companyName: documents[i].data.company.name,
          //signature: certificateName[certificateId],
          //deliveryStatus: 'รอระบบนำส่ง'
          amountTotal: _amountTotal,
          vatTotal: _vatTotal,
          grandTotal: _grandTotal,
          xmlData: documents[i].xmlFile,
          rdStatus: _rdStatus,
          documentDate: documents[i].data.dateBE,
        });
      }
      console.log("Docs data", _items);

      setItems(_items);
      //setLoadDataComplete(true);
      //setGetNewDocuments(false);

      sessionStorage.setItem("uploadXml-currentPage", currentPage);
    }
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    console.log("isCreateDocumentComplete useEffect: ", isProcessDocumentComplete);

    if (loadDataComplete && !isProcessDocument) {
      let _items = selection.getItems();
      console.log("_items: ", _items);

      let _selectedItems = selection.getSelection();
      console.log("_selectedItems: ", _selectedItems);

      if (isProcessDocumentComplete) {
        setIsProcessDocumentComplete(false);

        // let _items = selection.getItems();
        //let _keySelected = selection.getSelection()[0].key
        //console.log('_keySelected: ', _keySelected);
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== "") {
            _items[i].status = "";
          }
        }
        //selection.setAllSelected(true);
        //selection.setKeySelected(_keySelected, true, true);
        //setItems([...selection.getItems()]);
        // setItems([..._items]);
        //selection.setKeySelected(_keySelected, true, false);
      } else {
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== "") {
            _items[i].status = "";
          }
        }

        for (let i = 0; i < _selectedItems.length; i++) {
          // console.log("select item key: ", _selectedItems[i].key);
          // _selectedItems[i].status = 'pending';

          for (let j = 0; j < _items.length; j++) {
            //console.log('item key: ', _items);
            if (_items[j].key === _selectedItems[i].key) {
              // console.log("set pending item key: ", _items[j].key);
              _items[j].status = "pending";
              // console.log('_items: ', _items);

              // _selectedItems[i].status = "pending";
            }
            /* else {
            _items[j].status = "";
        } */
          }
        }
      }

      setItems(_items);
      //setSelectedItems(_selectedItems);
    }
    // eslint-disable-next-line
  }, [selectedItems]);

  /*
  useEffect(() => {
    if (!loadDataComplete) {
        const id = setInterval(() => {
            setPercentComplete((intervalIncrement + percentComplete) % 1);
           
            if (html != '') {
                setPercentComplete((intervalIncrement + 100) % 1);
                console.log('100%');
            }
            
        }, intervalDelay);
        return () => {
            clearInterval(id);
        };
    }
  });*/

  const download = (event, item) => {
    //console.log('Event ', event);
    //token = token;

    //b2cauth.run();

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        let _url = ADMIN_API_URL + "/documents/files/" + item.key;

        if (item.type === "RECEIPT-TAXINVOICE" || item.type === "RECEIPT-TAXINVOICE-ABB" || item.type === "RECEIPT" || item.type === "INVOICE-TAXINVOICE" || item.type === "DELIVERYORDER-TAXINVOICE" || item.type === "TAXINVOICE" || item.type === "DEBIT-NOTE" || item.type === "CREDIT-NOTE") {
          _url = ADMIN_API_URL + "/etax/files/" + item.key;
        }
        //axios.get(API_URL + '/documents/' + item.key, {
        //axios.get(ADMIN_API_URL + '/etax/files/' + item.key, {
        axios
          .get(_url, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
            responseType: "blob",
          })
          .then(
            (response) => {
              console.log(response.headers);

              const time = formatDateTime(item.createdTimeValue);

              //console.log('item: ', item);

              let fileName = time + "-" + item.type + "-" + item.number.replace(/\//g, "") + "." + item.fileExtension;

              console.log("file name: ", fileName);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", fileName); //or any other extension
              document.body.appendChild(link);
              link.click();
              link.remove();
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        /* 
if (error instanceof InteractionRequiredAuthError) {
    // fallback to interaction when silent call fails
    return instance.acquireTokenRedirect(tokenRequest)
}
*/

        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

        /* 
//if (error.errorMessage.indexOf("interaction_required") !== -1) {
if (error.errorMessage.includes("AADB2C90077")) {
  instance.acquireTokenRedirect(silentRequest);
}
*/
      });
  };

  const downloadXml = (event, item) => {
    //console.log('Event ', event);
    //token = token;

    //b2cauth.run();

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        //axios.get(API_URL + '/documents/' + item.key, {
        axios
          .get(ADMIN_API_URL + "/etax/files/" + item.key + "?extension=xml", {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
            responseType: "blob",
          })
          .then(
            (response) => {
              console.log(response.headers);

              const time = formatDateTime(item.createdTimeValue);

              //console.log('item: ', item);

              // let fileName = time + '-' + item.type + '-' + item.number.replace(/\//g,'') + '.' + item.fileExtension;
              let fileName = time + "-" + item.type + "-" + item.number.replace(/\//g, "") + ".xml";

              console.log("file name: ", fileName);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", fileName); //or any other extension
              document.body.appendChild(link);
              link.click();
              link.remove();
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        /* 
if (error instanceof InteractionRequiredAuthError) {
    // fallback to interaction when silent call fails
    return instance.acquireTokenRedirect(tokenRequest)
}
*/

        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

        /* 
//if (error.errorMessage.indexOf("interaction_required") !== -1) {
if (error.errorMessage.includes("AADB2C90077")) {
  instance.acquireTokenRedirect(silentRequest);
}
*/
      });
  };

  const reCreateFile = (event, item) => {
    let confirm = window.confirm("กรุณายืนยัน การสร้างไฟล์ XML เลขที่ " + item.number + " ใหม่แทนที่ไฟล์เดิม");

    if (confirm) {
      // setLoadDataComplete(false);

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          //axios.delete(API_URL + '/documents/' + item.key,
          axios
            .post(
              ADMIN_API_URL + "/etax/xml-recreate/" + item.key,
              {},
              {
                headers: {
                  Authorization: "Bearer " + tokenResponse.accessToken,
                },
              }
            )
            .then(
              (response) => {
                console.log("Finish reCreateXmlFile " + item.number + ": ", response);
                // setLoadDataComplete(false);
                // setGetNewDocuments(true);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }
  };

  const openFile = (event, item) => {
    if (item.fileExtension === "pdf") {
      openFilePdf(event, item);
    } else if (item.fileExtension === "xml") {
      openFileXml(event, item);
    }
  };

  const openFilePdf = (event, item) => {
    history.push({
      pathname: "/etax/documents/pdf/view",
      state: {
        item: item,
        backUrl: "/etax/documents/xml-service-provider"
        //documentId: item.key,
        //certificateName: item.signature
      },
    });
  };

  const openFileXml = (event, item) => {
    history.push({
      pathname: "/etax/documents/xml/view",
      state: {
        item: item,
        backUrl: "/etax/documents/xml-service-provider"
        /* 
    item: {
      key: item.id,
      signature: item.signatures[0].certificateName,
      createdTimeValue: item.createdTime,
      type: item.type,
      number: item.data.number,
      fileExtension: item.file.extension,
    }, */
        //documentId: item.key,
        //certificateName: item.signature
      },
    });
  };

  const onPageChange = (selectedPageIndex) => {
    console.log("selectedPageIndex: ", selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
    setGetNewDocuments(true);
    setLoadDataComplete(false);
  };

  const setRdStatusSelectedDocuments = (status) => {

    let _items = selection.getItems();
    for (let i = 0; i < selectedItems.length; i++) {
      console.log("Upload XML selected document: ", selectedItems[i]);
      for (let j = 0; j < _items.length; j++) {
        //console.log('item key: ', _items);
        if (_items[j].key === selectedItems[i].key) {
          console.log("item key: ", _items[j].key);

          if (status === "waiting") {
            if ((_items[j].rdStatusValue !== "sp-pending") && (_items[j].rdStatusValue !== "successful") && (_items[j].rdStatusValue !== "fail")) {

              // _items[j].rdStatus = t("xmlDeliveries.waiting");
              _items[j].rdStatus = "รอดำเนินการ";
              _items[j].rdStatusValue = "waiting";

            }
          }

          console.log("items: ", _items);
          setItems([..._items]);
        }
      }
    }

  }

  const uploadXmlSelectedDocuments = async () => {

    setRdStatusSelectedDocuments("waiting");

    let _items = selection.getItems();

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        for (let i = 0; i < selectedItems.length; i++) {
          console.log("Upload XML selected document: ", selectedItems[i]);

          setUploadingDocumentId(selectedItems[i].key);
          setUploadingDocumentNumber(selectedItems[i].number);
          setUploadingDocumentStatus("กำลังส่งไฟล์ XML...");

          console.log('uploadingXmlStop: ', uploadingXmlStop.current);

          if (uploadingXmlStop.current) {
            uploadingXmlStop.current = false;
            return;
          }

          // if ((selectedItems[i].rdStatusValue === 'sp-pending') || (selectedItems[i].rdStatusValue === 'successful') || (selectedItems[i].rdStatusValue === 'fail')) {
          if ((selectedItems[i].rdStatusValue === 'sp-pending') || (selectedItems[i].rdStatusValue === 'successful')) {
            console.log('Already uploaded xml or successful or fail, document id: ', selectedItems[i].key);
            continue;
          }

          for (let j = 0; j < _items.length; j++) {
            //console.log('item key: ', _items);
            if (_items[j].key === selectedItems[i].key) {
              console.log("item key: ", _items[j].key);
              // _items[j].rdStatus = t("xmlDeliveries.uploading");
              _items[j].rdStatus = "กำลังส่งข้อมูล";
              _items[j].rdStatusValue = "uploading";
              //_selectedItems[i].status = "pending";
              //setItems([]);
              //_items.sort();
              console.log("items: ", _items);
              setItems([..._items]);
              /* 
          await new Promise(resolve => {                        
              setTimeout(resolve, 1500)                        
          });
*/

              console.log("item: ", _items[j]);
              // let detail = await getInvoiceDetail(_items[j].number);

              const promiseXmlUpload = await new Promise(async (resolve) => {
                axios
                  .post(`${ADMIN_API_URL}/etax/xml/deliveries/${_items[j].key}`, {},
                    // .post(`http://localhost:7071/api/etax/xml/deliveries/${_items[j].key}`, {},
                    {
                      headers: {
                        Authorization: "Bearer " + tokenResponse.accessToken,
                        'sp': serviceProviderId
                        // 'cid': companyId,
                      },
                    }
                  )
                  .then(
                    async (response) => {
                      console.log("Upload XML response: ", response);

                      let count = 0;

                      let interval = await setInterval(async () => {
                        count += 1;

                        console.log('count: ', count);

                        if (response.data && (response.data?.convoId && response.data?.timestamp)) {

                          //Ginko Soft Response

                          // _items[j].rdStatus = t("xmlDeliveries.spPendingRdStatusShort");
                          _items[j].rdStatus = "นำส่งแล้ว";
                          _items[j].rdStatusValue = "sp-pending";

                          _items[j].rdDeliveredTime = toThaiDateString(response.data.timestamp);
                          _items[j].rdDeliveredTimeValue = response.data.timestamp;
                          _items[j].rdRefNumber = response.data.convoId;
                          // _items[j].rdStatusResponse = "สำเร็จ";


                          setItems([..._items]);

                          clearInterval(interval);

                          setUploadingDocumentStatus("นำส่งไฟล์ XML แล้ว");

                          setUploadingDocumentRefNo(response.data.convoId);

                          // setUploadingDocumentMessage(response.data?.data[0]?.data[0].message);

                          // setUploadingDocumentFileName(response.data?.data[0]?.filename);

                          // setUploadingDocumentRefNo(response.data?.data[0]?.refno);

                          resolve(true);

                        } else if (response.data && response.data?.data && (response.data?.data.resultCode === "200")) {

                          //Stream IT Consulting Response

                          let rdDeliveredTime = "-";
                          if (response.data?.data?.responseDTTM) {

                            rdDeliveredTime = response.data?.data?.responseDTTM.replaceAll("-", "/");

                          }

                          // _items[j].rdStatus = t("xmlDeliveries.spPendingRdStatusShort");
                          _items[j].rdStatus = "นำส่งแล้ว";
                          _items[j].rdStatusValue = "sp-pending";

                          // _items[j].rdDeliveredTime = toThaiDateString(response.data.timestamp);
                          _items[j].rdDeliveredTime = toThaiDateString(response.data?.data?.responseDTTM);
                          // _items[j].rdDeliveredTime = rdDeliveredTime;
                          _items[j].rdDeliveredTimeValue = rdDeliveredTime;
                          // _items[j].rdRefNumber = response.data.convoId;
                          // _items[j].rdStatusResponse = "สำเร็จ";

                          setItems([..._items]);

                          clearInterval(interval);

                          setUploadingDocumentStatus("นำส่งไฟล์ XML แล้ว");


                          // setUploadingDocumentStatus("อัปโหลดไฟล์ XML สำเร็จ");

                          // setUploadingDocumentMessage(response.data?.data[0]?.data[0].message);

                          // setUploadingDocumentFileName(response.data?.data[0]?.filename);

                          // setUploadingDocumentRefNo(response.data?.data[0]?.refno);

                          resolve(true);

                        } else if (response.data && response.data.$metadata && (response.data.$metadata?.httpStatusCode === 200)) {

                          //Brainergy Response

                          let rdDeliveredTime = new Date().toISOString(); 

                          _items[j].rdStatus = "นำส่งแล้ว";
                          _items[j].rdStatusValue = "sp-pending";

                          _items[j].rdDeliveredTime = toThaiDateString(rdDeliveredTime);
                          _items[j].rdDeliveredTimeValue = rdDeliveredTime;


                          setItems([..._items]);

                          clearInterval(interval);

                          setUploadingDocumentStatus("นำส่งไฟล์ XML แล้ว");

                          resolve(true);

                        } else if (count === 3) {

                          // _items[j].rdStatus = t("xmlDeliveries.failRdStatus");
                          _items[j].rdStatus = "นำส่งไม่สำเร็จ";
                          _items[j].rdStatusValue = "fail";

                          setItems([..._items]);

                          clearInterval(interval);

                          resolve(false);

                        }
                        // Sleep before upload
                        }, 1000);
                      // }, 300);
                    },
                    (error) => {
                      console.log("Error upload XML response: ", error);

                      // _items[j].rdStatus = t("xmlDeliveries.failRdStatus");
                      _items[j].rdStatus = "นำส่งไม่สำเร็จ";
                      _items[j].rdStatusValue = "fail";

                      setItems([..._items]);
                      resolve(false);
                    }
                  );
              }); //promise

              Promise.all([promiseXmlUpload]).then((value) => {
                console.log(i, value);
              });
            } //if
          } //for j
        } //for i
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    setIsProcessDocumentComplete(true);
    setIsProcessDocument(false);
  };

  const reCreateXmlSelectedDocuments = async () => {
    let _items = selection.getItems();

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        for (let i = 0; i < selectedItems.length; i++) {
          console.log("Recreate XML selected document: ", selectedItems[i]);

          setUploadingDocumentId(selectedItems[i].key);
          setUploadingDocumentNumber(selectedItems[i].number);
          setUploadingDocumentStatus("กำลังสร้างไฟล์ XML ใหม่...");

          console.log('uploadingXmlStop: ', uploadingXmlStop.current);

          if (uploadingXmlStop.current) {
            uploadingXmlStop.current = false;
            return;
          }

          if (selectedItems[i].rdStatus === 'นำส่งสำเร็จ') {
            console.log('Already uploaded xml, document id: ', selectedItems[i].key);
            break;
          }

          for (let j = 0; j < _items.length; j++) {
            //console.log('item key: ', _items);
            if (_items[j].key === selectedItems[i].key) {
              console.log("item key: ", _items[j].key);
              _items[j].status = "processing";
              //_selectedItems[i].status = "pending";
              //setItems([]);
              //_items.sort();
              console.log("items: ", _items);
              setItems([..._items]);
              /* 
          await new Promise(resolve => {                        
              setTimeout(resolve, 1500)                        
          });
*/

              console.log("item: ", _items[j]);
              // let detail = await getInvoiceDetail(_items[j].number);

              const promiseReCreateXml = await new Promise(async (resolve) => {
                axios
                  .post(
                    ADMIN_API_URL + "/etax/xml-recreate/" + _items[j].key,
                    {},
                    {
                      headers: {
                        Authorization: "Bearer " + tokenResponse.accessToken,
                      },
                    }
                  )
                  .then(
                    async (response) => {
                      console.log("Finish reCreateXmlFile " + _items[j].number + ": ", response);

                      let count = 0;

                      let interval = await setInterval(async () => {
                        count += 1;

                        console.log('count: ', count);

                        if (response.data && response.data.statusCode === 200) {
                          _items[j].status = "successful";
                          // _items[j].rdStatus = "นำส่งสำเร็จ";

                          _items[j].status = "recreate-xml-success";

                          // _items[j].rdDeliveredTime = toThaiDateString(moment(response.data?.data[0]?.sendgwdate).toISOString());
                          // _items[j].rdDeliveredTimeValue = response.data?.data[0]?.sendgwdate;
                          // _items[j].rdRefNumber = response.data?.data[0]?.refno;
                          // _items[j].rdStatusResponse = "สำเร็จ";


                          setItems([..._items]);

                          clearInterval(interval);

                          setUploadingDocumentStatus("สร้างไฟล์ XML สำเร็จ");

                          setUploadingDocumentMessage(response.data.message);

                          // setUploadingDocumentFileName(response.data?.data[0]?.filename);

                          // setUploadingDocumentRefNo(response.data?.data[0]?.refno);

                          resolve(true);
                        } else if (count === 3) {
                          // array[i].status = 'ไม่สำเร็จ';
                          // _items[j].rdStatus = "นำส่งไม่สำเร็จ";
                          _items[j].status = "fail";

                          _items[j].rdStatusResponse = "ไม่สำเร็จ";

                          setItems([..._items]);

                          clearInterval(interval);

                          setUploadingDocumentStatus("สร้างไฟล์ XML ไม่สำเร็จ");

                          if (response.data?.message) {
                            console.log('Recreate XML fail message: ', response.data.message);
                            setUploadingDocumentMessage(response.data.message);
                          } else {
                            setUploadingDocumentMessage('');

                          }

                          setUploadingDocumentFileName("");

                          setUploadingDocumentRefNo("");

                          resolve(false);
                        }
                      }, 1000);

                    },
                    (error) => {
                      console.log(error);

                      _items[j].status = "fail";
                      setItems([..._items]);
                      resolve(false);
                    }

                  );
              }); //promise

              Promise.all([promiseReCreateXml]).then((value) => {
                console.log(i, value);
              });
            } //if
          } //for j
        } //for i
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        //instance.acquireTokenRedirect(silentRequest);
      });

    setIsProcessDocumentComplete(true);
    setIsProcessDocument(false);
  };

  const columns = [
    {
      key: "column1",
      name: "วันที่สร้าง",
      fieldName: "createdTime",
      minWidth: 70,
      maxWidth: 125,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "createdTime" ? true : false,
      isSortedDescending: isSortedDescendingCreatedTime,
      isSortedAscending: !isSortedDescendingCreatedTime,
      onColumnClick: () => {
        console.log("Sort created time!");
        setPrimarySort("createdTime");
        setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        // setPreviousSearch('');
        // setSearch('');

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem("uploadXml-primarySort", "createdTime");
        sessionStorage.setItem("uploadXml-sort-createdTime", !isSortedDescendingCreatedTime === true ? "true" : "false");

        sessionStorage.setItem("uploadXml-sort-number", "false");
        sessionStorage.setItem("uploadXml-sort-documentDate", "false");
        sessionStorage.setItem("uploadXml-sort-customerName", "false");
        sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
        sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
        sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
        sessionStorage.setItem("uploadXml-sort-name", "false");

        if (!isSortedDescendingCreatedTime) {
          setSortDirection("DESC");
          sessionStorage.setItem("uploadXml-sortDirection", "DESC");
        } else {
          setSortDirection("ASC");
          sessionStorage.setItem("uploadXml-sortDirection", "ASC");
        }
      },
      data: "string",
      onRender: (item) => {
        if (item.deleted) {
          return <span style={{ color: "#a4262c" }}>{item.createdTime}</span>;
        }
        return <span>{item.createdTime}</span>;
      },
      // isPadded: true,
    },
    {
      key: "column2",
      name: "เลขที่",
      fieldName: "number",
      minWidth: 80,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "number" ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log("Sort document number!");
        setPrimarySort("number");
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        // setPreviousSearch("");
        // setSearch("");

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem("uploadXml-primarySort", "number");
        sessionStorage.setItem("uploadXml-sort-number", !isSortedDescendingNumber === true ? "true" : "false");

        sessionStorage.setItem("uploadXml-sort-createdTime", "false");
        sessionStorage.setItem("uploadXml-sort-documentDate", "false");
        sessionStorage.setItem("uploadXml-sort-customerName", "false");
        sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
        sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
        sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
        sessionStorage.setItem("uploadXml-sort-name", "false");

        if (!isSortedDescendingNumber) {
          setSortDirection("DESC");
          sessionStorage.setItem("uploadXml-sortDirection", "DESC");
        } else {
          setSortDirection("ASC");
          sessionStorage.setItem("uploadXml-sortDirection", "ASC");
        }
      },
      data: "string",
      /* 
      onRender: (item) => {
          return (
              <TooltipHost content={item.key}>
                  <ActionButton
                      title="ดาวน์โหลด"
                      className={classNames.fileIconImg}
                      onClick={(event) => {
                          download(event, item);
                      }}
                  >
                      {item.deleted ? <span style={{ color: "#a4262c" }}>{item.number}</span> : item.number}
                  </ActionButton>
              </TooltipHost>
          );
      },
*/
      onRender: (item) => {
        return <Link onClick={() => {
          navigator.clipboard.writeText(item.key);
          setCopySuccess(true);
          window.scrollTo(0, 0);
        }}>{item.deleted
          ? <span style={{ color: '#a4262c' }} data-selection-disabled={true}>{item.number}</span>
          : <span data-selection-disabled={true}>{item.number}</span>}</Link>;
      },
      // isPadded: true,
    },
    {
      key: "column3",
      name: "วันที่บนเอกสาร",
      fieldName: "documentDate",
      minWidth: 70,
      maxWidth: 70,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "documentDate" ? true : false,
      isSortedDescending: isSortedDescendingDocumentDate,
      isSortedAscending: !isSortedDescendingDocumentDate,
      onColumnClick: () => {
        console.log("Sort documentDate!");
        setPrimarySort("documentDate");
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        // setPreviousSearch("");
        // setSearch("");

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem("uploadXml-primarySort", "documentDate");
        sessionStorage.setItem("uploadXml-sort-documentDate", !isSortedDescendingDocumentDate === true ? "true" : "false");

        sessionStorage.setItem("uploadXml-sort-createdTime", "false");
        sessionStorage.setItem("uploadXml-sort-number", "false");
        sessionStorage.setItem("uploadXml-sort-customerName", "false");
        sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
        sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
        sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
        sessionStorage.setItem("uploadXml-sort-name", "false");

        if (!isSortedDescendingDocumentDate) {
          setSortDirection("DESC");
          sessionStorage.setItem("uploadXml-sortDirection", "DESC");
        } else {
          setSortDirection("ASC");
          sessionStorage.setItem("uploadXml-sortDirection", "ASC");
        }
      },
      data: "string",
      onRender: (item) => {
        return <TooltipHost content={item.documentDate}>{item.deleted ? <span style={{ color: "#a4262c" }}>{item.documentDate}</span> : <span>{item.documentDate}</span>}</TooltipHost>;
      },
      //isPadded: true,
    },
    {
      key: "column4",
      name: "ผู้ขาย",
      fieldName: "companyName",
      minWidth: 50,
      maxWidth: 250,
      isRowHeader: true,
      isResizable: true,
      //isSorted: true,
      //isSortedDescending: true,
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        return <TooltipHost content={item.companyName}>{item.deleted ? <span style={{ color: "#a4262c" }}>{item.companyName}</span> : <span>{item.companyName}</span>}</TooltipHost>;
      },
      // isPadded: true,
    },
    {
      key: "column5",
      name: "ผู้ซื้อ",
      fieldName: "customerName",
      minWidth: 50,
      maxWidth: 150,
      // isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "customerName" ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log("Sort customerName!");
        setPrimarySort("customerName");
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        // setPreviousSearch("");
        // setSearch("");

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem("uploadXml-primarySort", "customerName");
        sessionStorage.setItem("uploadXml-sort-customerName", !isSortedDescendingCustomerName === true ? "true" : "false");

        sessionStorage.setItem("uploadXml-sort-createdTime", "false");
        sessionStorage.setItem("uploadXml-sort-number", "false");
        sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
        sessionStorage.setItem("uploadXml-sort-documentDate", "false");
        sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
        sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
        sessionStorage.setItem("uploadXml-sort-name", "false");

        if (!isSortedDescendingCustomerName) {
          setSortDirection("DESC");
          sessionStorage.setItem("uploadXml-sortDirection", "DESC");
        } else {
          setSortDirection("ASC");
          sessionStorage.setItem("uploadXml-sortDirection", "ASC");
        }
      },
      data: "string",
      onRender: (item) => {
        return <TooltipHost content={item.customerName}>{item.deleted ? <span style={{ color: "#a4262c" }}>{item.customerName}</span> : item.customerName}</TooltipHost>;
      },
      // isPadded: true,
    },
    {
      key: "column6",
      name: "ยอด (รวม Vat)",
      fieldName: "grandTotal",
      minWidth: 70,
      maxWidth: 70,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "grandTotal" ? true : false,
      isSortedDescending: isSortedDescendingGrandTotal,
      isSortedAscending: !isSortedDescendingGrandTotal,
      onColumnClick: () => {
        console.log("Sort grandTotal!");
        setPrimarySort("grandTotal");
        setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        // setPreviousSearch("");
        // setSearch("");

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem("uploadXml-primarySort", "grandTotal");
        sessionStorage.setItem("uploadXml-sort-grandTotal", !isSortedDescendingGrandTotal === true ? "true" : "false");

        sessionStorage.setItem("uploadXml-sort-createdTime", "false");
        sessionStorage.setItem("uploadXml-sort-number", "false");
        sessionStorage.setItem("uploadXml-sort-documentDate", "false");
        sessionStorage.setItem("uploadXml-sort-customerName", "false");
        sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
        sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
        sessionStorage.setItem("uploadXml-sort-name", "false");

        if (!isSortedDescendingGrandTotal) {
          setSortDirection("DESC");
          sessionStorage.setItem("uploadXml-sortDirection", "DESC");
        } else {
          setSortDirection("ASC");
          sessionStorage.setItem("uploadXml-sortDirection", "ASC");
        }
      },
      data: "number",
      onRender: (item) => {
        if (item.deleted) {
          return (
            <span
              style={{
                display: "block",
                textAlign: "right",
                color: "#a4262c",
              }}
            >
              {item.grandTotal.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </span>
          );
        }

        return (
          <span style={{ display: "block", textAlign: "right" }}>
            {item.grandTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      // isPadded: true,
    },
    {
      key: "column7",
      name: "เอกสาร",
      fieldName: "name",
      minWidth: 50,
      maxWidth: 180,
      isCollapsible: true,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "name" ? true : false,
      isSortedDescending: isSortedDescendingName,
      isSortedAscending: !isSortedDescendingName,
      onColumnClick: () => {
        console.log("Sort name!");
        setPrimarySort("name");
        setIsSortedDescendingName(!isSortedDescendingName);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        // setPreviousSearch("");
        // setSearch("");

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem("uploadXml-primarySort", "name");
        sessionStorage.setItem("uploadXml-sort-name", !isSortedDescendingName === true ? "true" : "false");

        sessionStorage.setItem("uploadXml-sort-createdTime", "false");
        sessionStorage.setItem("uploadXml-sort-number", "false");
        sessionStorage.setItem("uploadXml-sort-documentDate", "false");
        sessionStorage.setItem("uploadXml-sort-customerName", "false");
        sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
        sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
        sessionStorage.setItem("uploadXml-sort-grandTotal", "false");

        if (!isSortedDescendingName) {
          setSortDirection("DESC");
          sessionStorage.setItem("uploadXml-sortDirection", "DESC");
        } else {
          setSortDirection("ASC");
          sessionStorage.setItem("uploadXml-sortDirection", "ASC");
        }
      },
      data: "string",
      onRender: (item) => {
        return (
          <ActionButton
            title="เปิดไฟล์ XML"
            className={classNames.fileIconImg}
            onClick={(event) => {
              openFileXml(event, item);
            }}
          >
            {item.deleted ? <span style={{ color: "#a4262c" }}>{item.name}</span> : item.name}
          </ActionButton>
        );
      },
      // isPadded: true,
    },
    {
      key: "column8",
      name: "ลบ",
      fieldName: "deleted",
      minWidth: 20,
      maxWidth: 20,
      isResizable: true,
      //isSorted: true,
      //isSortedDescending: true,
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        return item.deleted ? (
          <FontIcon
            iconName="Delete"
            className={mergeStyles({
              fontSize: 18,
              // height: 50,
              // width: 50,
              // margin: '0 25px',
              color: "#a4262c",
            })}
          />
        ) : (
          <span style={{ display: "block", textAlign: "center" }}>-</span>
        );
      },

      // isPadded: true,
    },
    {
      key: "column9",
      name: "สถานะนำส่ง",
      fieldName: "status",
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
      //isSorted: true,
      //isSortedDescending: true,
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        /* 
                        return item.isUploadXml ? (
                            <Spinner size={SpinnerSize.small} />
                        ) : (item.rdStatus === 'นำส่งไม่สำเร็จ') ? (
                            <span
                                style={{
                                    display: "block",
                                    textAlign: "center",
                                    color: "#a4262c",
                                }}
                            >
                                {item.rdStatus}
                            </span>
                        ) : (
                            <span style={{ display: "block", textAlign: "center" }}>{item.rdStatus}</span>
                        );
                         */

        let _status = "-";
        if ((item.rdStatusValue === "") || (item.rdStatusValue === null)) {
          _status = <span style={{ display: 'block', textAlign: 'center' }}>-</span>
        } else if (item.rdStatusValue === "successful") {
          _status = <div data-selection-disabled={true}><span style={{ color: "green" }}>{"นำส่งสำเร็จ"}</span></div>
        } else if (item.rdStatusValue === "sp-pending") {
          _status = <div data-selection-disabled={true}><TooltipHost content={"นำส่งแล้ว (รอสถานะ)"}><span style={{ color: "#106EBE" }}>{"นำส่งแล้ว"}</span></TooltipHost></div>
        } else if (item.rdStatusValue === "fail") {
          _status = <div data-selection-disabled={true}><span style={{ color: "red" }}>{"นำส่งไม่สำเร็จ"}</span></div>
        } else if (item.rdStatusValue === "uploading") {
          _status = <Spinner label={"กำลังส่งข้อมูล"} labelPosition="right" style={{ marginLeft: '0px' }} />
        } else if (item.rdStatusValue === "waiting") {
          _status = <span style={{ color: "#106EBE" }}>{"รอดำเนินการ"}</span>
        }

        return (<div style={{ display: "flex", alignItems: "start", flexDirection: "row" }}>{_status}</div>);
      },

      // isPadded: true,
    },
    {
      key: "column10",
      name: "วันที่นำส่ง",
      fieldName: "rdDeliveredTime",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      //isSorted: true,
      //isSortedDescending: true,
      isCollapsible: true,
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        return (
          <TooltipHost content={item.rdDeliveredTime}>
            <span>{item.rdDeliveredTime}</span>
          </TooltipHost>
        );
      },
      //isPadded: true,
    },
    {
      key: "column11",
      name: "หมายเลขนำส่ง",
      fieldName: "rdRefNumber",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      //isSorted: true,
      //isSortedDescending: true,
      isCollapsible: true,
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        return (
          <TooltipHost content={item.rdRefNumber}>
            <span>{item.rdRefNumber}</span>
          </TooltipHost>
        );
      },
      //isPadded: true,
    },
/* 
    {
      key: "column12",
      name: "Status",
      fieldName: "rdStatusResponse",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      //isSorted: true,
      //isSortedDescending: true,
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        // return <span style={{ display: "block", textAlign: "center" }}>{item.rdStatusResponse}</span>;
        return (item.rdStatusResponse === 'ไม่สำเร็จ') ? (
          <span
            style={{
              display: "block",
              textAlign: "center",
              color: "#a4262c",
            }}
          >
            {item.rdStatusResponse}
          </span>
        ) : (
          <span style={{ display: "block", textAlign: "center" }}>{item.rdStatusResponse}</span>
        );
      },
      //isPadded: true,
    },
     */
    {
      key: "column13",
      name: "สร้างโดย",
      fieldName: "createdBy",
      minWidth: 30,
      maxWidth: 50,
      isResizable: true,
      //isSorted: true,
      //isSortedDescending: true,
      onColumnClick: "",
      data: "string",
      /* 
                  onRender: (item) => {
                      return (
                          <TooltipHost content={item.createdBy}>
                              {item.deleted ? (
                                  <span
                                      style={{
                                          display: "block",
                                          textAlign: "center",
                                          color: "#a4262c",
                                      }}
                                  >
                                      {item.createdBy}
                                  </span>
                              ) : (
                                  <span
                                      style={{
                                          display: "block",
                                          textAlign: "center",
                                      }}
                                  >
                                      {item.createdBy}
                                  </span>
                              )}
                          </TooltipHost>
                      );
                  },
       */
      onRender: (item) => {
        return <Link onClick={() => {
          navigator.clipboard.writeText(item.createdBy);
          setCopySuccess(true);
          window.scrollTo(0, 0);
        }}>{item.deleted
          ?
          <span style={{ display: "block", textAlign: "center", color: '#a4262c' }} data-selection-disabled={true}>
            {item.createdBy}
          </span>
          :
          <span style={{ display: "block", textAlign: "center" }} data-selection-disabled={true}>
            {item.createdBy}
          </span>
          }</Link>;
      },
      // isPadded: true,
    },
    {
      key: "column14",
      name: "ไฟล์",
      // className: classNames.fileIconCell,
      // iconClassName: classNames.fileIconHeaderIcon,
      // ariaLabel: 'Column operations for File type, Press to sort on File type',
      // iconName: 'Page',
      // isIconOnly: true,
      fieldName: "name",
      minWidth: 70,
      maxWidth: 70,
      onColumnClick: "",

      onRender: (item) => (
        <>
          {/* <TooltipHost content={`ไฟล์ ${item.fileExtension}`}> */}
          {/* <img src={item.iconName} className={classNames.fileIconImg} alt={`${item.fileExtension} file icon`} /> */}
          <IconButton
            onClick={(event) => {
              openFile(event, item);
            }}
            iconProps={{
              ...getFileTypeIconProps({
                extension: item.fileExtension,
                size: 32,
                imageFileType: "svg",
              }),
            }}
          />
          {/* </TooltipHost> */}
          {item.isPdfXml && (
            <IconButton
              onClick={(event) => {
                openFileXml(event, item);
              }}
              iconProps={{
                ...getFileTypeIconProps({
                  extension: "xml",
                  size: 32,
                  imageFileType: "svg",
                }),
              }}
            />
          )}
        </>
      ),
    },

    /*       {
              key: "column15",
              name: "",
              fieldName: "command",
              minWidth: 100,
              maxWidth: 100,
              isResizable: true,
              //isCollapsible: true,
              data: "string",
              onColumnClick: "",
              onRender: (item) => {
                  if (isProcessDocument || isProcessDocumentComplete) {
                      if (item.status === "pending") {
                          return (
                              <span style={{ color: "#106EBE" }}>
                                  รอดำเนินการ
                              </span>
                          );
                          // return <Spinner label="กำลังสร้างเอกสาร..." labelPosition="right"/>
                      } else if (item.status === "processing") {
                          return (
                              <Spinner
                                  label="กำลังสร้างเอกสาร..."
                                  labelPosition="right"
                                  style={{ marginLeft: "-35px" }}
                              />
                          );
                      } else if (item.status === "successful") {
                          return <span style={{ color: "green" }}>สำเร็จ!</span>;
                      } else if (item.status === "fail") {
                          return <span style={{ color: "red" }}>ไม่สำเร็จ!</span>;
                      } else if (item.status === "deleting") {
                          return (
                              <Spinner
                                  label="กำลังลบเอกสาร..."
                                  labelPosition="right"
                                  style={{ marginLeft: "-35px" }}
                              />
                          );
                      } else if (item.status === "deleted") {
                          return (
                              <span style={{ color: "green" }}>
                                  ลบเอกสารสำเร็จ!
                              </span>
                          );
                      }
                  } else {
                      return (
                          <span data-selection-disabled={true}>
                              {isAdmin
                                  ?
                                  <DefaultButton
                                      text="เลือก"
                                      //iconProps={{ iconName: 'Add' }}
                                      menuProps={{
                                          items: [
                                              {
                                                  key: "open",
                                                  text: "เปิดเอกสาร",
                                                  iconProps: { iconName: "OpenFile" },
                                                  onClick: (event) => {
                                                      openFile(event, item);
                                                  },
                                              },
                                              {
                                                  key: "download",
                                                  text: "ดาวน์โหลด",
                                                  iconProps: {
                                                      iconName: "DownloadDocument",
                                                  },
                                                  onClick: (event) => {
                                                      download(event, item);
                                                  },
                                              },
                                              {
                                                  key: "create",
                                                  text: "สร้างไฟล์ PDF ใหม่",
                                                  iconProps: {
                                                      iconName: "RepeatAll",
                                                  },
                                                  onClick: (event) => {
                                                      reCreatePdfFile(event, item);
                                                  },
                                              },
                                              {
                                                  key: "divider_1",
                                                  itemType:
                                                      ContextualMenuItemType.Divider,
                                              },
                                           
                                          ],
                                          shouldFocusOnMount: true,
                                          directionalHintFixed: true,
                                      }}
                                      // Optional callback to customize menu rendering
                                      menuAs={(props) => (
                                          <ContextualMenu {...props} />
                                      )}
                                  // Optional callback to do other actions (besides opening the menu) on click
                                  //onMenuClick={(ev) => { console.log(ev); }}
                                  // By default, the ContextualMenu is re-created each time it's shown and destroyed when closed.
                                  // Uncomment the next line to hide the ContextualMenu but persist it in the DOM instead.
                                  // persistMenu={true}
                                  //allowDisabledFocus
                                  //disabled={(props) => props}
                                  //checked={(props) => props}
                                  />
                                  :
                                  <DefaultButton
                                      text="เลือก"
                                      //iconProps={{ iconName: 'Add' }}
                                      menuProps={{
                                          items: [
                                              {
                                                  key: "open",
                                                  text: "เปิดเอกสาร",
                                                  iconProps: { iconName: "OpenFile" },
                                                  onClick: (event) => {
                                                      openFile(event, item);
                                                  },
                                              },
                                              {
                                                  key: "download",
                                                  text: "ดาวน์โหลด",
                                                  iconProps: {
                                                      iconName: "DownloadDocument",
                                                  },
                                                  onClick: (event) => {
                                                      download(event, item);
                                                  },
                                              },
                                          ],
                                          shouldFocusOnMount: true,
                                          directionalHintFixed: true,
                                      }}
                                      // Optional callback to customize menu rendering
                                      menuAs={(props) => (
                                          <ContextualMenu {...props} />
                                      )}
                                  // Optional callback to do other actions (besides opening the menu) on click
                                  //onMenuClick={(ev) => { console.log(ev); }}
                                  // By default, the ContextualMenu is re-created each time it's shown and destroyed when closed.
                                  // Uncomment the next line to hide the ContextualMenu but persist it in the DOM instead.
                                  // persistMenu={true}
                                  //allowDisabledFocus
                                  //disabled={(props) => props}
                                  //checked={(props) => props}
                                  />
                              }
                          </span>
                      );
                  }
              },
          },
   */
    {
      key: "column16",
      name: "",
      fieldName: "command",
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      //isCollapsible: true,
      data: "string",
      onColumnClick: "",
      onRender: (item) => {
        if (isProcessDocument || isProcessDocumentComplete) {
          // if (item.rdStatusValue === "pending") {
          // return <span style={{ color: "#106EBE" }}>รอดำเนินการ</span>;
          // return <Spinner label="กำลังสร้างเอกสาร..." labelPosition="right"/>
          // } else 
          if (item.rdStatusValue === "processing") {
            return <Spinner label="กำลังดำเนินการ..." labelPosition="right" style={{ marginLeft: "-35px" }} />;
          } else if (item.rdStatusValue === "successful") {
            return <span style={{ color: "green" }}>สำเร็จ!</span>;
          } else if (item.rdStatusValue === "fail") {
            return <span style={{ color: "red" }}>ไม่สำเร็จ!</span>;
          } else if (item.rdStatusValue === "deleting") {
            return <Spinner label="กำลังลบเอกสาร..." labelPosition="right" style={{ marginLeft: "-35px" }} />;
          } else if (item.rdStatusValue === "deleted") {
            return <span style={{ color: "green" }}>ลบเอกสารสำเร็จ!</span>;
          } else if (item.rdStatusValue === "uploading") {
            return <Spinner label="กำลังส่งข้อมูล..." labelPosition="right" style={{ marginLeft: "-35px" }} />;
          } else if (item.rdStatusValue === "uploaded") {
            return <span style={{ color: "green" }}>อัปโหลดสำเร็จ!</span>;
          } else if (item.rdStatusValue === "recreate-xml-success") {
            return <span style={{ color: "green" }}>สร้างไฟล์ XML สำเร็จ!</span>;
          } else if (item.rdStatusValue === "sp-pending") {
            return <div data-selection-disabled={true}><TooltipHost content={"นำส่งแล้ว (รอสถานะ)"}><span style={{ color: "#106EBE" }}>{"นำส่งแล้ว (รอสถานะ)"}</span></TooltipHost></div>
          } else if (item.rdStatusValue === "waiting") {
            return <span style={{ color: "#106EBE" }}>{"รอดำเนินการ"}</span>
          }
        } else {
          return (
            <span data-selection-disabled={true}>
              {isAdmin ? (
                <DefaultButton
                  text="เลือก"
                  //iconProps={{ iconName: 'Add' }}
                  menuProps={{
                    items: [
                      /* 
                                                                  {
                                                                      key: "upload",
                                                                      text: "อัปโหลดไฟล์ XML",
                                                                      iconProps: {
                                                                          iconName: "CloudUpload",
                                                                      },
                                                                      //disabled: item.rdRefNumber ? false : true,
                                                                      disabled: item.rdStatus !== "นำส่งสำเร็จ" ? false : true,
                                                                      onClick: (event) => {
                                                                          // uploadXml(event, item);
                      
                                                                          console.log("Upload");
                                                                          console.log("selectionItems: ", selectedItems);
                      
                                                                          let confirm = window.confirm(`กรุณายืนยัน การอัปโหลดไฟล์ XML เอกสารเลขที่ ${item.number} (${item.key})`);
                      
                                                                          if (confirm && isAdmin) {
                                                                              uploadXml(event, item);
                                                                              // deleteSelectedDocuments();
                                                                          }
                      
                                                                      },
                                                                  },
                                                                   */
                      {
                        key: "divider_1",
                        itemType: ContextualMenuItemType.Divider,
                      },
                      {
                        key: "open-xml",
                        text: "เปิดเอกสาร XML",
                        iconProps: {
                          iconName: "OpenFile",
                        },
                        onClick: (event) => {
                          openFileXml(event, item);
                        },
                      },
                      {
                        key: "download-xml",
                        text: "ดาวน์โหลด XML",
                        iconProps: {
                          iconName: "DownloadDocument",
                        },
                        onClick: (event) => {
                          downloadXml(event, item, "xml");
                        },
                      },
                      /* 
                                                                      {
                                                                          key: "divider_2",
                                                                          itemType:
                                                                              ContextualMenuItemType.Divider,
                                                                      },  
                                                                       */
                      {
                        key: "open-pdf",
                        text: "เปิดเอกสาร PDF",
                        iconProps: {
                          iconName: "OpenFile",
                        },
                        onClick: (event) => {
                          openFile(event, item);
                        },
                      },
                      {
                        key: "download-pdf",
                        text: "ดาวน์โหลด PDF",
                        iconProps: {
                          iconName: "DownloadDocument",
                        },
                        onClick: (event) => {
                          download(event, item, "pdf");
                        },
                      },
                      {
                        key: "divider_3",
                        itemType: ContextualMenuItemType.Divider,
                      },
                      /* 
                                                                  {
                                                                      key: "login",
                                                                      text: "ล็อกอิน e-Tax RD",
                                                                      iconProps: {
                                                                          iconName: "Signin",
                                                                      },
                                                                      //className: { classNames: classNames.fileIconImg },
                                                                      // styles: {
                                                                      //     root: {
                                                                      //         //fontSize: 20,
                                                                      //         color: '#D65633',
                                                                      //     }
                                                                      // },
                                                                      onClick: (event) => {
                                                                          console.log("e-Tax Login press");
                                                                          console.log("log in to company: ", item.companyName);
                      
                                                                          let confirm = window.confirm(`กรุณายืนยัน การ Log in e-Tax RD ${item.companyName} ${item.createdBy}`);
                      
                                                                          if (confirm && isAdmin) {
                                                                              setEtaxUserLastLogin('กำลังล็อกอินเข้าระบบ e-Tax RD...');
                                                                              etaxLogin(item.createdBy);
                                                                          }
                                                                      },
                                                                  },
                                                                   */
                      {
                        key: "create",
                        text: "สร้างไฟล์ XML ใหม่",
                        iconProps: {
                          iconName: "RepeatAll",
                        },
                        //className: { classNames: classNames.fileIconImg },
                        // styles: {
                        //     root: {
                        //         //fontSize: 20,
                        //         color: '#D65633',
                        //     }
                        // },
                        disabled: item.rdStatus !== "นำส่งสำเร็จ" ? false : true,
                        onClick: (event) => {
                          reCreateFile(event, item);
                        },
                      },
                      /* 
                                                                      {
                                                                          key: "divider_1",
                                                                          itemType:
                                                                              ContextualMenuItemType.Divider,
                                                                      },
                                                                      {
                                                                          key: "delete",
                                                                          text: "ลบไฟล์ XML",
                                                                          iconProps: { iconName: "Delete" },
                                                                          //className: { classNames: classNames.fileIconImg },
                                                                          // styles: {
                                                                          //     root: {
                                                                          //         //fontSize: 20,
                                                                          //         color: '#D65633',
                                                                          //     }
                                                                          // },
                                                                          onClick: (event) => {
                                                                              deleteFile(event, item);
                                                                          },
                                                                      },
                                                                       */
                    ],
                    shouldFocusOnMount: true,
                    directionalHintFixed: true,
                  }}
                  // Optional callback to customize menu rendering
                  menuAs={(props) => <ContextualMenu {...props} />}
                // Optional callback to do other actions (besides opening the menu) on click
                //onMenuClick={(ev) => { console.log(ev); }}
                // By default, the ContextualMenu is re-created each time it's shown and destroyed when closed.
                // Uncomment the next line to hide the ContextualMenu but persist it in the DOM instead.
                // persistMenu={true}
                //allowDisabledFocus
                //disabled={(props) => props}
                //checked={(props) => props}
                />
              ) : (
                <DefaultButton
                  text="เลือก"
                  //iconProps={{ iconName: 'Add' }}
                  menuProps={{
                    items: [
                      {
                        key: "open-xml",
                        text: "เปิดเอกสาร XML",
                        iconProps: {
                          iconName: "OpenFile",
                        },
                        onClick: (event) => {
                          openFileXml(event, item);
                        },
                      },
                      {
                        key: "download-xml",
                        text: "ดาวน์โหลด XML",
                        iconProps: {
                          iconName: "DownloadDocument",
                        },
                        onClick: (event) => {
                          downloadXml(event, item, "xml");
                        },
                      },
                      {
                        key: "open-pdf",
                        text: "เปิดเอกสาร PDF",
                        iconProps: {
                          iconName: "OpenFile",
                        },
                        onClick: (event) => {
                          openFile(event, item);
                        },
                      },
                      {
                        key: "download-pdf",
                        text: "ดาวน์โหลด PDF",
                        iconProps: {
                          iconName: "DownloadDocument",
                        },
                        onClick: (event) => {
                          download(event, item, "pdf");
                        },
                      },
                    ],
                    shouldFocusOnMount: true,
                    directionalHintFixed: true,
                  }}
                  // Optional callback to customize menu rendering
                  menuAs={(props) => <ContextualMenu {...props} />}
                // Optional callback to do other actions (besides opening the menu) on click
                //onMenuClick={(ev) => { console.log(ev); }}
                // By default, the ContextualMenu is re-created each time it's shown and destroyed when closed.
                // Uncomment the next line to hide the ContextualMenu but persist it in the DOM instead.
                // persistMenu={true}
                //allowDisabledFocus
                //disabled={(props) => props}
                //checked={(props) => props}
                />
              )}
            </span>
          );
        }
      },
    },
  ];

  const isCompactMode = false;
  //const isModealSelection = false;
  //const annoucedMessage = undefined;

  return (
    <div style={homeStyles}>
      <Stack>
        {showRepeatNumber && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            onDismiss={() => setShowRepeatNumber(false)}
            dismissButtonAriaLabel="ปิด"
            style={{
              fontSize: 14,
            }}
          >
            <TooltipHost content={duplicatedNumbers.join(", ")} directionalHint={DirectionalHint.bottomCenter}>
              <span style={{ width: 1000, whiteSpace: 'nowrap', overflow: 'hidden', display: 'inline-block', textOverflow: "ellipsis" }}>
                {"ระบบพบว่ามีเลขที่เอกสารซ้ำกัน"} : {duplicatedNumbers.join(", ")}
              </span>
            </TooltipHost>
          </MessageBar>
        )}
        {copySuccess &&
          <MessageBar

            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={() => { setCopySuccess(false) }}
          >
            Copy สำเร็จ!
          </MessageBar>
        }
        <center>
          <h2 style={{ marginLeft: "0px", marginTop: "25px" }}>เอกสาร XML (Service Provider)</h2>
          {/* <h4>( สำหรับส่งให้กรมสรรพากร )</h4> */}
          {/* <br/> */}
        </center>
      </Stack>

      <Stack
        horizontal
        horizontalAlign="center"
        //wrap

        tokens={{ childrenGap: "15px" }}
      >
        <DocumentCard
          aria-label="รอนำส่ง"
          styles={cardStyles.cardStyles}
          type={DocumentCardType.compact}
        //onClickHref="http://bing.com"
        >
          <DocumentCardLogo {...{ logoIcon: "Documentation" }} title="รอนำส่ง" />
          {/* <div className={conversationTileClass}> */}
          <DocumentCardDetails>
            <DocumentCardTitle
              title="รอนำส่ง"
              //shouldTruncate
              styles={cardStyles.header}
            />
            {/* <Text styles={cardStyles.amount}>{totalDocuments} ใบ</Text> */}
            <Text styles={cardStyles.amount}>
              <CountUp end={totalPending} separator="," duration={1} /> เอกสาร
            </Text>
            {/* </div>  */}
          </DocumentCardDetails>
        </DocumentCard>

        <DocumentCard
          aria-label="นำส่งแล้ว (รอสถานะ)"
          styles={cardStyles.cardStyles}
          type={DocumentCardType.compact}
        //onClickHref="http://bing.com"
        >
          <DocumentCardLogo {...{ logoIcon: "Recent" }} title="นำส่งแล้ว (รอสถานะ)" />
          {/* <div className={conversationTileClass}> */}
          <DocumentCardDetails>
            <DocumentCardTitle
              title="นำส่งแล้ว (รอสถานะ)"
              //shouldTruncate
              styles={cardStyles.header}
            />
            {/* <Text styles={cardStyles.amount}>{totalGrand.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บาท</Text> */}
            <Text styles={cardStyles.amount}>
              <CountUp end={totalSpPending} separator="," duration={1} /> เอกสาร
            </Text>
            {/* </div>  */}
          </DocumentCardDetails>
        </DocumentCard>

        <DocumentCard
          aria-label="นำส่งไม่สำเร็จ"
          styles={cardStyles.cardStyles}
          type={DocumentCardType.compact}
        //onClickHref="http://bing.com"
        >
          <DocumentCardLogo {...{ logoIcon: "Info" }} title="นำส่งไม่สำเร็จ" />
          {/* <div className={conversationTileClass}> */}
          <DocumentCardDetails>
            <DocumentCardTitle
              title="นำส่งไม่สำเร็จ"
              //shouldTruncate
              styles={cardStyles.header}
            />
            {/* <Text styles={cardStyles.amount}>{totalGrand.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บาท</Text> */}
            <Text styles={cardStyles.amount}>
              <CountUp end={totalFail} separator="," duration={1} /> เอกสาร
            </Text>
            {/* </div>  */}
          </DocumentCardDetails>
        </DocumentCard>

        <DocumentCard
          aria-label="นำส่งสำเร็จ"
          styles={cardStyles.cardStyles}
          type={DocumentCardType.compact}
        //onClickHref="http://bing.com"
        >
          <DocumentCardLogo {...{ logoIcon: "Completed" }} title="นำส่งสำเร็จ" />
          {/* <div className={conversationTileClass}> */}
          <DocumentCardDetails>
            <DocumentCardTitle
              title="นำส่งสำเร็จ"
              //shouldTruncate
              styles={cardStyles.header}
            />
            {/* <Text styles={cardStyles.amount}>{totalVat.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บาท</Text> */}
            <Text styles={cardStyles.amount}>
              <CountUp end={totalSuccessful} separator="," duration={1} /> เอกสาร
            </Text>
            {/* </div>  */}
          </DocumentCardDetails>
        </DocumentCard>
      </Stack>
      <br />

      <Stack horizontal horizontalAlign="center">
        <h3>{companiesFilter.text}</h3>
      </Stack>

      <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
        <Stack vertical horizontalAlign="end" style={{ marginLeft: "5px" }}>
          {/* <span>lastLogin: </span> */}
          {/* <br /> */}
          <span>document id: </span>
          <span>เลขที่เอกสาร: </span>
          <span>สถานะนำส่ง XML: </span>
          <br />
          <span>Ref Number: </span>
          <br />
          {/* <span>ชื่อไฟล์: </span> */}
          <span>ข้อความตอบกลับ: </span>
        </Stack>

        <Stack vertical horizontalAlign="start" style={{ width: "500px" }}>
          {/* <span>{etaxUserLastLogin}&nbsp;</span> */}
          {/* <br /> */}
          <span>{uploadingDocumentId}&nbsp;</span>
          <span>{uploadingDocumentNumber}&nbsp;</span>
          <span>{uploadingDocumentStatus}&nbsp;</span>
          <br />
          <span>{uploadingDocumentRefNo}&nbsp;</span>
          <br />
          {/* <span>{uploadingDocumentFileName}&nbsp;</span> */}
          <span>{uploadingDocumentMessage}&nbsp;</span>
        </Stack>
      </Stack>
      <br />

      <Stack horizontal horizontalAlign="center" style={{ width: "98%" }} tokens={{ childrenGap: "15px" }}>
        {/* 
                <DefaultButton
                    text="ล็อกอิน e-Tax RD"
                    style={{ width: '150px' }}
                    onClick={() => {

                        console.log("e-Tax Login press");
                        console.log("log in to company: ", companiesFilter.text);

                        let confirm = window.confirm(
                            "กรุณายืนยัน การ Log in e-Tax RD " +
                                companiesFilter.text
                        );

                        if (confirm && isAdmin) {
                            etaxLogin();
                        }

                    }}
                />  
 */}
        {!isProcessDocument && (<PrimaryButton
          text="อัปโหลดไฟล์ XML"
          style={{ width: "200px", marginLeft: "25px" }}
          disabled={!loadDataComplete || isProcessDocument || selectedItems.length === 0 || !isAdmin}
          iconProps={{ iconName: 'Upload' }}
          onClick={() => {
            console.log("Upload");
            console.log("selectionItems: ", selectedItems);

            let confirm = window.confirm("กรุณายืนยัน การอัปโหลดไฟล์ XML เอกสารที่เลือก จำนวน " + selectedItems.length + " เอกสาร");

            if (confirm && isAdmin) {
              setIsProcessDocument(true);
              uploadXmlSelectedDocuments();
              // deleteSelectedDocuments();
            }
            /* 
            setLoadDataComplete(false);
            setDocuments([]);
            setItems([]);
            setGetNewDocuments(true);

            setTotalDocuments(0);
            setTotalVat(0);
            setTotalGrand(0);
             */
          }}
        />
        )}

        {isProcessDocument && (
          <PrimaryButton
            text="หยุดทำงาน"
            style={{ width: "200px", marginLeft: "25px" }}
            disabled={uploadingXmlStop.current}
            iconProps={{ iconName: 'StopSolid' }}
            onClick={() => {
              // setUploadingXmlStop(true);

              uploadingXmlStop.current = true;
            }}
          />
        )}
      </Stack>
      <br />
{/* 
      <Stack horizontal horizontalAlign="center">
        ส่งผ่าน Service Provider:{" "}
        {serviceProviderId === serviceProvider.GinkgoSoft ? "บริษัท กิงโกะ ซอฟต์ จำกัด" : ""}{" "}
      </Stack>
      <br />
 */}
      <Stack horizontal horizontalAlign="center">       
        <Dropdown
          // placeholder="Select an option"
          label="ส่งผ่าน Service Provider:"
          // style={{ width: "150px" }}
          styles={{
            dropdown: { width: 250 },
          }}
          options={[
            { key: serviceProvider.GinkgoSoft, text: 'บริษัท กิงโกะ ซอฟต์ จำกัด' },
            { key: serviceProvider.Brainergy, text: 'บริษัท เบรนเนอร์จี้ จำกัด' }            
          ]}
          selectedKey={serviceProviderId}
          disabled={!loadDataComplete || isProcessDocument}
          onChange={(e, selectedOption) => {
            console.log(selectedOption);
            setServiceProviderId(selectedOption.key);
          }}
        />
      </Stack>
      <br />

      <Stack horizontal horizontalAlign="center">
        จำนวนเอกสารที่ยังไม่นำส่ง{" "}
        {totalNotYetDelivered.toLocaleString(undefined, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })}{" "}
        เอกสาร
      </Stack>
      <br />

      <Stack horizontal horizontalAlign="center">
        จำนวนเอกสาร{" "}
        {totalDocuments.toLocaleString(undefined, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })}{" "}
        เอกสาร, ภาษีขาย{" "}
        {totalVat.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })}{" "}
        บาท, ยอดขาย (รวม Vat){" "}
        {totalGrand.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })}{" "}
        บาท
      </Stack>
      <br />

      <Stack horizontal horizontalAlign="center">
        <Stack
          vertical
          style={{
            width: "98%",
            //minWidth: '1070px',
            maxWidth: "1350px",
          }}
        >
          <CommandBar
            items={commandBarItems}
          //overflowItems={_overflowItems}
          //overflowButtonProps={overflowProps}
          //farItems={_farItems}
          //ariaLabel="Use left and right arrow keys to navigate between commands"
          />
          <Stack
            horizontal
            horizontalAlign="space-around"
            style={{
              backgroundColor: "#FFFFFF",
              // paddingLeft: '22px'
            }}
          >
            <Stack vertical style={{ width: "18%", marginLeft: "-23px" }}>
              <CommandBar
                items={commandBarItems2}
              //overflowItems={_overflowItems}
              //overflowButtonProps={overflowProps}
              //farItems={_farItems}
              //ariaLabel="Use left and right arrow keys to navigate between commands"
              />
            </Stack>
            <Stack vertical style={{ width: "42%" }}>
              <Pivot
                // disabled={!loadDataComplete || isProcessDocument}
                selectedKey={String(pageSize)}
                onLinkClick={(item) => {
                  console.log("Pivot item: ", item.props.itemKey);

                  switch (item.props.itemKey) {
                    case "10":
                      console.log("10 เอกสาร/หน้า");
                      setPageSize(10);
                      sessionStorage.setItem("uploadXml-pageSize", 10);

                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setTotalDocuments(0);
                      setTotalVat(0);
                      setTotalGrand(0);
                      break;
                    case "50":
                      console.log("50 เอกสาร/หน้า");
                      setPageSize(50);
                      sessionStorage.setItem("uploadXml-pageSize", 50);

                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setTotalDocuments(0);
                      setTotalVat(0);
                      setTotalGrand(0);
                      break;
                    case "100":
                      console.log("100 เอกสาร/หน้า");
                      setPageSize(100);
                      sessionStorage.setItem("uploadXml-pageSize", 100);

                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setTotalDocuments(0);
                      setTotalVat(0);
                      setTotalGrand(0);
                      break;
                    case "300":
                      console.log("300 เอกสาร/หน้า");
                      setPageSize(300);
                      sessionStorage.setItem("uploadXml-pageSize", 300);

                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setTotalDocuments(0);
                      setTotalVat(0);
                      setTotalGrand(0);
                      break;
                    case "500":
                      console.log("500 เอกสาร/หน้า");
                      setPageSize(500);
                      sessionStorage.setItem("uploadXml-pageSize", 500);

                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setTotalDocuments(0);
                      setTotalVat(0);
                      setTotalGrand(0);
                      break;
                    default:
                  }
                }}
              >
                <PivotItem headerText="10 เอกสาร/หน้า" itemIcon="NumberedList" itemKey="10" />
                {/* <PivotItem headerText="20 เอกสาร/หน้า" itemCount={42} itemIcon="Page"/>
                                <PivotItem headerText="30 เอกสาร/หน้า" itemCount={42} itemIcon="Page"/>
                                <PivotItem headerText="40 เอกสาร/หน้า" itemCount={42} itemIcon="Page"/> */}
                <PivotItem headerText="50 เอกสาร/หน้า" itemIcon="NumberedList" itemKey="50" />
                <PivotItem headerText="100 เอกสาร/หน้า" itemIcon="NumberedList" itemKey="100" />
                <PivotItem headerText="300 เอกสาร/หน้า" itemIcon="NumberedList" itemKey="300" />
                <PivotItem headerText="500 เอกสาร/หน้า" itemIcon="NumberedList" itemKey="500" />
              </Pivot>
            </Stack>

            <Stack vertical horizontalAlign="end" style={{ width: "30%" }}>
              {/* 
                            <PrimaryButton 
                                text="นำส่งไฟล์ XML" 
                                style={{width: '150px'}}
                                onClick={() => {
                                    
                                }} 
                            />
                             */}
            </Stack>
          </Stack>
          <ShimmeredDetailsList
            items={items || []}
            enableShimmer={!loadDataComplete}
            compact={isCompactMode}
            columns={columns}
            // selectionMode={SelectionMode.none}
            //selectionMode={SelectionMode.multiple}
            selection={selection}
            // selectionMode={SelectionMode.none}
            selectionMode={!loadDataComplete || isProcessDocument || !isAdmin ? SelectionMode.none : SelectionMode.multiple}
            //getKey="1"
            //setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            checkboxVisibility={CheckboxVisibility.always}
            //selection={Selection}
            selectionPreservedOnEmptyClick={true}
          //onItemInvoked={download}
          //enterModalSelectionOnTouch={true}
          //ariaLabelForSelectionColumn="Toggle selection"
          //ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          //checkButtonAriaLabel="Row checkbox"
          //onRenderItemColumn={ this.onRenderItemColumn }
          //listProps={shimmeredDetailsListProps}
          />
        </Stack>
      </Stack>
      {/* </Fabric> */}
      <br />
      <center>
        {
          /* {!(documents.length === 0 || documents.length < pageSize) && */
          loadDataComplete && (
            <Pagination
              //selectedPageIndex={page}
              selectedPageIndex={currentPage - 1}
              //pageCount={pageCount}
              pageCount={pageData.totalPages}
              onPageChange={onPageChange}
              format
              firstPageIconProps={{
                iconName: "DoubleChevronLeft",
              }}
              previousPageIconProps={{ iconName: "ChevronLeft" }}
              nextPageIconProps={{ iconName: "ChevronRight" }}
              lastPageIconProps={{
                iconName: "DoubleChevronRight",
              }}
            />
          )
        }

        <br />
        <br />

        {/* 
            <br/>
            <a href="http://nav.cx/3p1xabz" target="_blank">
                <img src={PUBLIC_URL + '/images/line-add-friend.png'} alt="เพิ่มเพื่อน" height="36" border="0"></img>
            </a>
            <br/>
            <br/>
            <div>ฝ่ายบริการลูกค้า Line: @leceipt</div>
            <br/>
            <div>โทร. 082-579-8555</div>
            <br/>
            <div>เวลาทำการ 8.30 น. - 17.30 น. ทุกวัน</div>
 */}
      </center>
    </div>
  );
};

export default AllDocuments;
